import { useState, useCallback, useEffect } from 'react';
import useDebounceCallback from '../../useDebounceCallback';
import useBaseDataLoad from '../useBaseDataLoad';
import useToken from '../../useToken';
import { handleErrors } from './default-error-handler';
import useSpace from '../../useSpace';

const DEFAULT_STATE = {
  data: [],
  pagination: {},
  isLoading: true,
  status: 0,
  errors: null,
};

export const createListingHook = (
  getEntities,
  defaultParams = {},
  defaultOptions = {},
) => {
  /**
   * @param { object } params
   * @param { { handleErrors: boolean, debounce: number } } hookOptions
   * @returns {{
   *    data: array,
   *    pagination: { count: number, current_page: number, total_count: number, total_pages: number },
   *    isLoading: boolean,
   *    status: number,
   *    errors: object | null,
   *    reload: function,
   *  }|[
   *   data: array,
   *   pagination: { count: number, current_page: number, total_count: number, total_pages: number },
   *   isLoading: boolean,
   *   status: number,
   *   errors: object | null,
   *   reload: function,
   * ]}
   */
  return (params = defaultParams, hookOptions = defaultOptions) => {
    const jwt = useToken();
    const { space } = useSpace();
    const { debounce = 0 } = hookOptions || defaultOptions;
    const [{ data, pagination, isLoading, status, errors }, setResponseInfo] =
      useState(DEFAULT_STATE);

    const loadData = useBaseDataLoad(
      jwt,
      space,
      getEntities,
      params,
      hookOptions || defaultOptions,
    );

    const reload = useCallback(async () => {
      if (!jwt || hookOptions?.pause) return;
      setResponseInfo((responseInfo) =>
        responseInfo.isLoading
          ? responseInfo
          : {
              ...responseInfo,
              isLoading: true,
            },
      );
      try {
        const { body, status } = await loadData();
        setResponseInfo({
          data: body.data,
          pagination: {
            total_pages: body.total_pages,
            total_count: body.total_count,
            count: body.count,
            current_page: body.current_page,
          },
          isLoading: false,
          status,
          errors: null,
        });
      } catch (error) {
        handleErrors(error, setResponseInfo, DEFAULT_STATE);
      }
    }, [loadData, jwt, hookOptions?.pause]);

    const debouncedReload = useDebounceCallback(reload, debounce);
    useEffect(debouncedReload, [debouncedReload]);

    const result = [
      data,
      pagination,
      isLoading,
      status,
      errors,
      debouncedReload,
    ];
    Object.assign(result, {
      data,
      pagination,
      isLoading,
      status,
      errors,
      reload: debouncedReload,
    });
    return result;
  };
};
