import { useCallback, useState, useContext, useMemo, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import * as Sentry from '@sentry/react';

// :: Components
import Sidebar from '../components/Sidebar/Sidebar';
import Topbar from '../components/Topbar/Topbar';
import FloatingHelp from '../components/FloatingHelp/FloatingHelp';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';
import AnnouncementComponent from '../components/Announcement/AnnouncementComponent';

// :: Context
import AppContext from '../contexts/AppContext';

// :: Utils
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

// :: Lib
import { getHash, getTestProps } from '../lib/helpers';

// :: Images
import {
  FlotiqLogo,
  FlotiqBadgeLogo,
  LogOutIcon,
  PlayCircleIcon,
  DocumentTextIcon,
  InformationSignIcon,
  ComputerDesktopIcon,
  FlotiqLogoWhite,
} from '../images/shapes';

// :: Hooks
import useDarkMode from '../hooks/useDarkMode';

const Structure = forwardRef(
  (
    {
      menuUserItems,
      logoLink,
      additionalElement,
      testId,
      additionalClasses,
      children,
      sidebarMenuItems,
      pinContentCallback,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { appContext } = useContext(AppContext);
    const [darkMode] = useDarkMode();

    const announcement =
      process.env.REACT_APP_ANNOUNCEMENT.split(',').join(',');

    const announcementHash = useMemo(() => {
      return getHash(announcement);
    }, [announcement]);

    const announcementHide = useMemo(() => {
      if (!announcement) return true;
      const announcementData =
        getLocalStorage('cms.user_hideAnnouncement', true) || {};
      if (announcementData.id === announcementHash)
        return announcementData.hide;
      return false;
    }, [announcement, announcementHash]);

    const [hideAnnouncement, setHideAnnouncement] = useState(announcementHide);

    const handleCloseAnnouncement = useCallback(() => {
      setHideAnnouncement(true);
      setLocalStorage(`cms.user_hideAnnouncement`, {
        id: announcementHash,
        hide: true,
      });
    }, [announcementHash]);

    // Sidebar
    const localstoreSideBarOpenStatus = getLocalStorage(
      'cms.user_sideBarOpen',
      true,
    );

    const isSizeSM = useMediaQuery({
      query: '(max-width: 1024px)',
    });

    const [sideBarOpen, setSidebarOpen] = useState(
      localstoreSideBarOpenStatus !== null
        ? localstoreSideBarOpenStatus
        : !isSizeSM,
    );

    const handleSidebar = useCallback((value) => {
      setSidebarOpen(value);

      // Case: Save in localstorage user preference of open/close sidebar
      setLocalStorage('cms.user_sideBarOpen', value);
    }, []);

    const MENU_USER_ITEMS = useMemo(
      () => [
        {
          icon: (
            <LogOutIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
          ),
          text: t('Global.LogOut'),
          link: '/logout',
        },
      ],
      [t],
    );

    const FLOATING_HELP_CONTENT = useMemo(
      () => [
        {
          label: t('FloatingHelp.Tutorials'),
          icon: <PlayCircleIcon className="w-6 text-blue pr-2" />,
          link: process.env.REACT_APP_TUTORIALS,
          key: 'Tutorials',
        },
        {
          label: t('FloatingHelp.Documentation'),
          icon: <DocumentTextIcon className="w-6 text-blue pr-2" />,
          link: process.env.REACT_APP_DOCUMENTATION,
          key: 'Documentation',
        },
        {
          label: t('FloatingHelp.Support'),
          icon: <InformationSignIcon className="w-6 text-blue pr-2" />,
          link: process.env.REACT_APP_DISCORD,
          key: 'Support',
        },
        {
          label: t('FloatingHelp.Demo'),
          icon: <ComputerDesktopIcon className="w-6 text-blue pr-2" />,
          link: process.env.REACT_APP_DEMO,
          key: 'Demo',
        },
      ],
      [t],
    );

    const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

    return (
      <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
        <AnnouncementComponent
          additionalClasses="h-16"
          announcement={announcement}
          handleClose={handleCloseAnnouncement}
          hasCloseButton={true}
          hidden={hideAnnouncement}
          {...getTestProps(testId, 'announcement', 'testId')}
        />
        <main
          ref={ref}
          className={twMerge(
            'flex items-start justify-between brand-bg-gradient w-full',
            'dark:bg-gray-900 from-blue-700 to-blue-650',
            'min-h-screen py-24 md:pb-0 font-inter',
            'transition-all',
            'ease-in-out',
            'duration-normal relative ',
            sideBarOpen
              ? 'pl-0 sm:pl-[68px] lg:pl-[240px]'
              : 'pl-0 sm:pl-[68px]',
            hideAnnouncement ? 'py-14' : 'py-32',
            additionalClasses,
          )}
          data-testid={testId}
        >
          {additionalElement}
          <Topbar
            isSidebarOpen={sideBarOpen}
            topbarLogo={<FlotiqBadgeLogo className="h-10" />}
            topbarHeading={appContext?.topBar?.heading || ''}
            topbarButtons={appContext?.topBar?.buttons || []}
            additionalClasses={twMerge(
              'fixed right-0 z-20 w-full',
              'transition-all',
              'ease-in-out',
              'duration-normal',
              hideAnnouncement ? 'top-0' : 'top-16',
              sideBarOpen
                ? 'pl-3 sm:pl-[68px] lg:pl-[240px]'
                : 'pl-3 sm:pl-[68px]',
            )}
            breadcrumbs={appContext?.breadcrumbs || []}
            {...getTestProps(appContext?.topBar?.testId, 'topbar', 'testId')}
          />
          <Sidebar
            additionalContainerClasses={twMerge(
              'fixed top-14 left-0 z-20',
              hideAnnouncement ? 'top-0' : 'top-16',
            )}
            logo={
              darkMode ? (
                <FlotiqLogoWhite className="h-10" />
              ) : (
                <FlotiqLogo className="h-10" />
              )
            }
            logoLink={logoLink || '/'}
            menuItems={sidebarMenuItems || appContext?.sidebarMenuItems}
            logoConfig={{
              height: 64,
              marginBottom: 36,
              marginTop: hideAnnouncement ? 0 : 64,
            }}
            menuConfig={{
              selected: appContext?.page,
              openItem: appContext?.menuItemOpen,
            }}
            userConfig={{
              name: `${appContext?.user?.firstName || ''} ${
                appContext?.user?.lastName || ''
              }`,
              email: appContext?.user?.email || '',
              height: 64,
              isModalOnClose: true,
              isModalOnCloseOutside: true,
              hasEventOnCloseOutside: true,
              menuItem: menuUserItems || MENU_USER_ITEMS,
            }}
            isOpen={sideBarOpen}
            handleSidebar={handleSidebar}
            pinContentCallback={pinContentCallback}
            additionalUserEmailClasses={
              'break-words leading-normal whitespace-normal mr-2 pb-0.5 line-clamp-2'
            }
            {...getTestProps(testId, 'sidebar', 'testId')}
          />
          {children ? children : <Outlet />}
          <FloatingHelp
            content={FLOATING_HELP_CONTENT}
            additionalClasses="hidden xs:block fixed right-1.5 md:right-3 bottom-2 mb-safe md:mb-0 md:bottom-3"
          />
        </main>
      </Sentry.ErrorBoundary>
    );
  },
);

export default Structure;
