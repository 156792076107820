import { createContext } from 'react';
import PropTypes from 'prop-types';

export const UserContext = createContext({});

UserContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Role permissions for user
     */
    permissions: PropTypes.shape({}),
    /**
     * If user has admin role for user's organization
     */
    isAdmin: PropTypes.bool,
    /**
     * If user is role admin from admin organizations
     */
    isRoleAdmin: PropTypes.bool,
    /**
     * User data from local storage
     */
    userStorage: PropTypes.shape({}),
    /**
     * Base user data for event tracking
     */
    baseUserEventData: PropTypes.shape({}),
  }).isRequired,
};

export default UserContext;
