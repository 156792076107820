import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Popover from '../Popover/Popover';
import { Link } from 'react-router-dom';

// :: Icons
import { QuestionMarkIcon } from '../../images/shapes';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const FloatingHelp = ({
  content,
  floatingHelpPanelPlacement,
  additionalClasses,
  testId,
}) => {
  const floatingHelpButton = (
    <div
      className="flex items-center justify-center bg-blue rounded-full w-12 sm:w-16 h-12 sm:h-16 hover:bg-blue-600
      transition duration-300 ease-in-out"
      {...getTestProps(testId, 'popover')}
    >
      <QuestionMarkIcon className="w-3 sm:w-4 text-white" />
    </div>
  );

  const floatingHelpContent = (
    <>
      {content?.map((item) => (
        <Link
          to={item.link}
          key={item.key}
          className="flex items-center hover:text-blue-600 dark:text-white py-1 lg:py-2 text-sm sm:text-base"
          rel="noreferrer"
          target="_blank"
        >
          <div>{item.icon}</div>
          {item.label}
        </Link>
      ))}
    </>
  );

  return (
    <div
      className={twMerge('fixed bottom-4 right-0 z-20', additionalClasses)}
      {...getTestProps(testId, 'container')}
    >
      <Popover
        popoverButton={floatingHelpButton}
        content={floatingHelpContent}
        popoverPanelPlacement={floatingHelpPanelPlacement}
        additionalPopoverPanelClasses="w-[286px]"
      />
    </div>
  );
};

export default FloatingHelp;

FloatingHelp.propTypes = {
  /**
   * content for Floating help popover
   */
  content: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      key: PropTypes.string.isRequired,
      icon: PropTypes.node,
      label: PropTypes.node.isRequired,
    }),
  ),
  /**
   *Floating help popover placement
   */
  floatingHelpPanelPlacement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'leftTop',
    'leftCenter',
    'leftBottom',
  ]),
  /**
   * Additional CSS classes for popover panel
   */
  additionalClasses: PropTypes.string,
  /**
   * Floating help test id
   */
  testId: PropTypes.string,
};

FloatingHelp.defaultProps = {
  content: [],
  floatingHelpPanelPlacement: 'topRight',
  additionalClasses: '',
  testId: '',
};
