import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

// :: Lib
import { getFormatedSize, getTestProps } from '../../../lib/helpers';

// :: Component Inner
import TypeCardRow from './TypeCardRow';

const SpaceTypeCard = ({
  name,
  price,
  color,
  selected,
  plan,
  handleSelected,
  additionalClasses,
  disabled,
  limit,
  testId,
}) => {
  const { t } = useTranslation();

  const panelColor = {
    gray: 'bg-slate-200 dark:bg-gray-800 text-[#141046] dark:text-white',
    orange: 'bg-orange dark:bg-orange-900 text-[#141046] dark:text-white',
    blue: 'bg-blue-600 dark:bg-blue-900 text-white',
    green: 'bg-green-500 dark:bg-green-900 text-[#141046] dark:text-white',
    disabled: 'bg-[#ececec] dark:bg-gray-700 text-[#727090] dark:text-gray-500',
  };

  const planDetails = useMemo(() => {
    const footerDetails = [];
    const bodyDetails = [
      {
        id: `monthly-calls-${plan.name}`,
        value: getFormatedSize(plan.apiCallsLimit, 'compact-number', 'Custom'),
        title: t('Spaces.APICallsMo'),
      },
      {
        id: `objects-limits-${plan.name}`,
        value: getFormatedSize(plan.ctoLimit, 'compact-number', 'Custom'),
        title: t('Spaces.COLimit'),
      },
      {
        id: `object-types-${plan.name}`,
        value: getFormatedSize(plan.ctdLimit, '', 'Custom'),
        title: t('Spaces.CTDLimit'),
      },
      {
        id: `file-quota-${plan.name}`,
        value: getFormatedSize(plan.fileQuota, 'MB', 'Custom'),
        title: t('Spaces.FileQuota'),
      },
      {
        id: `team-members-${plan.name}`,
        value: getFormatedSize(plan.teamMembersLimit, '', 'Custom'),
        title: t('Spaces.TeamMembers'),
      },
      {
        id: `webhooks-${plan.name}`,
        value: getFormatedSize(plan.webhooksLimit, '', 'Custom'),
        title: t('Spaces.Webhooks'),
      },
      {
        id: `scoped-api-keys-${plan.name}`,
        value: getFormatedSize(plan.scopedKeysLimit, '', 'Custom'),
        title: t('Spaces.ScopedApiKey'),
      },
      {
        id: `support-${plan.name}`,
        value: getFormatedSize(plan.support, '', 'Custom'),
        title: plan.support && t('Spaces.Support'),
      },
    ];

    return { body: bodyDetails, footer: footerDetails };
  }, [plan, t]);

  return (
    <div
      className={twMerge(
        'rounded-lg bg-transparent cursor-pointer',
        'relative group',
        selected && 'shadow-[0px_4px_20px_0px_#00000026]',
        disabled &&
          'bg-gray-200 hover:bg-gradient-transparent cursor-not-allowed',
        additionalClasses,
      )}
      {...(!disabled && {
        onClick: () => handleSelected(plan),
      })}
      {...getTestProps(testId, 'container')}
    >
      <div
        className={twMerge(
          'absolute w-[calc(100%+4px)] h-[calc(100%+4px)] m-[-2px] rounded-lg',
          'bg-transparent group-hover:bg-gradient-greenBlue',
          selected && 'bg-gradient-greenBlue',
          disabled && 'group-hover:bg-gradient-transparent',
        )}
        {...getTestProps(testId, 'border-box')}
      />
      <div
        className={twMerge(
          'relative w-full rounded-lg overflow-hidden bg-white dark:bg-gray-900 dark:text-white h-full',
          'border border-slate-200 dark:border-gray-800 pb-1.5',
          selected && 'border-0',
          disabled && 'bg-[#f4f4f4]',
        )}
      >
        {/* Section: head */}
        <div
          className={twMerge(
            'h-[50px] flex items-center px-4 justify-between',
            panelColor[disabled ? 'disabled' : color],
          )}
          {...getTestProps(testId, 'panel')}
        >
          <span className="font-bold text-lg" {...getTestProps(testId, 'name')}>
            {name}
            <span
              className={'font-bold text-lg ml-2'}
              {...getTestProps(testId, 'limit')}
            >
              {limit}
            </span>
          </span>

          <span
            className="font-bold text-xl"
            {...getTestProps(testId, 'price')}
          >
            {price}
          </span>
        </div>

        {/* Section: sub */}
        <div className="p-4">
          <div className="pb-3 font-bold" {...getTestProps(testId, 'sub')}>
            {t('Spaces.PlanIncludes')}:
          </div>

          {/* Section: body */}
          <div className="text-black flex flex-col">
            {planDetails?.body?.map((el) => (
              <TypeCardRow
                key={el.id}
                title={el.title}
                value={el.value}
                additionalValueClass={el.valueClass}
                {...getTestProps(testId, `body-row-${el.id}`, 'testId')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceTypeCard;

SpaceTypeCard.propTypes = {
  /**
   *  Space name
   */
  name: PropTypes.string,
  /**
   *  Space price
   */
  price: PropTypes.string,
  /**
   *  If card is selected
   */
  selected: PropTypes.bool,
  /**
   *  Plan object
   */
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    spaceLicense: PropTypes.number,
    bandwidth: PropTypes.number,
    teamMembersLimit: PropTypes.number,
    scopedKeysLimit: PropTypes.number,
    apiCallsLimit: PropTypes.number,
    ctoLimit: PropTypes.number,
    ctdLimit: PropTypes.number,
    fileQuota: PropTypes.number,
  }),
  /**
   *  On select handler
   */
  handleSelected: PropTypes.func,
  /**
   *  Card color
   */
  color: PropTypes.string,
  /**
   *  Additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   *  Test Id
   */
  testId: PropTypes.string,
};

SpaceTypeCard.defaultProps = {
  name: '',
  price: '',
  selected: false,
  plan: {},
  handleSelected: undefined,
  color: 'gray',
  additionalClasses: '',
  testId: '',
  limit: '',
};
