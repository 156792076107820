import { useMemo } from 'react';
import {
  getConstraints,
  deleteContentObject,
  deleteContentType,
  getContentObject,
  getContentObjects,
  getContentType,
  listContentTypes,
  putContentObject,
  listUnsplashMedia,
  putContentType,
  getSearchObject,
  getUser,
  putUser,
  deleteUser,
  getUsers,
  getContentObjectVersions,
  getApiKey,
  listWebhooks,
  getWorkflow,
  getWorkflowDefinition,
  getPlugins,
  getAllUsers,
  getPluginsLibrary,
  getUserRoles,
  getUserRole,
  putUserRole,
  deleteUserRole,
  getPlans,
  getPublicPlans,
  getPlan,
  putPlan,
  deletePlan,
  getAllSpaces,
  getSpace,
  putSpace,
  deleteSpace,
  putSpaceHasPlan,
  getSpacesList,
  getSpacePlanHistory,
} from '../../lib/flotiq-client';
import { createEntityHook } from './hook-builders/entity-hook-builder';
import { createListingHook } from './hook-builders/listing-hook-builder';

export const useContentTypes = createListingHook(listContentTypes);

export const useContentType = createEntityHook(
  getContentType,
  putContentType,
  deleteContentType,
  'contentTypeName',
);

const baseCTOHook = createListingHook(getContentObjects);

export const useContentObjects = (
  contentTypeName,
  optionalParams = null,
  options = null,
) => {
  const completeParams = useMemo(
    () => ({ contentTypeName, ...optionalParams }),
    [contentTypeName, optionalParams],
  );
  return baseCTOHook(completeParams, options);
};

const baseObjectHook = createEntityHook(
  getContentObject,
  putContentObject,
  deleteContentObject,
  'id',
);
export const useContentObject = (contentTypeName, id) => {
  const params = useMemo(() => ({ contentTypeName }), [contentTypeName]);
  return baseObjectHook(id, params);
};

const baseObjectVersionsHook = createListingHook(getContentObjectVersions);
export const useContentObjectVersions = (
  contentTypeName,
  id,
  hookParams = null,
) => {
  const params = useMemo(
    () => ({ contentTypeName, id, ...hookParams }),
    [contentTypeName, hookParams, id],
  );
  return baseObjectVersionsHook(params);
};

export const useUnsplashMedia = createListingHook(listUnsplashMedia);

export const useMediaTags = (params) => {
  return useContentObjects('_tag', params);
};

export const useConstraints = createEntityHook(
  getConstraints,
  null,
  null,
  'name',
);

export const useDefinitionsCount = (hookParams, hookOptions) => {
  return useConstraints('cto-count', hookParams, hookOptions);
};

export const useWorkflow = (objectType, id, revision) => {
  const params = useMemo(
    () => ({ objectType, revision }),
    [objectType, revision],
  );
  return baseWorkflowHook(id, params);
};

export const useWorkflowDefinition = createEntityHook(
  getWorkflowDefinition,
  null,
  null,
  'name',
);
export const baseWorkflowHook = createEntityHook(
  getWorkflow,
  null,
  null,
  'objectId',
);

export const useUsers = createListingHook(getUsers);

export const useUser = createEntityHook(getUser, putUser, deleteUser);

export const useAllUsers = createListingHook(getAllUsers);

export const useAllSpaces = createListingHook(getAllSpaces);

export const useSearch = createListingHook(getSearchObject);

export const useApiKeys = createListingHook(getApiKey);

export const useWebhook = createListingHook(listWebhooks);

export const usePlugins = createListingHook(getPlugins);

export const usePluginsLibrary = createListingHook(getPluginsLibrary);

export const useUserRoles = createListingHook(getUserRoles);

export const useUserRole = createEntityHook(
  getUserRole,
  putUserRole,
  deleteUserRole,
);

export const useAllPlans = createListingHook(getPlans);
export const useAllPublicPlans = createListingHook(getPublicPlans);

export const usePlan = createEntityHook(getPlan, putPlan, deletePlan, 'id');

export const usePluginsSettings = (params) => {
  return useContentObjects('_plugin_settings', params);
};

export const useSpace = createEntityHook(getSpace, putSpace, deleteSpace, 'id');

export const useSpaceHasPlan = createEntityHook(
  null,
  putSpaceHasPlan,
  null,
  'spaceId',
);

export const useSpacesList = createListingHook(getSpacesList);

const baseSpacePlanHistory = createListingHook(getSpacePlanHistory);
export const useSpacePlanHistory = (
  spaceId,
  optionalParams = null,
  options = null,
) => {
  const completeParams = useMemo(
    () => ({ id: spaceId, ...optionalParams }),
    [spaceId, optionalParams],
  );
  return baseSpacePlanHistory(completeParams, options);
};
