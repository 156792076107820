import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Components
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import DataGridControl from '../../components/DataGridControl/DataGridControl';

// :: Utils
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';

// :: Hooks
import { useAllSpaces } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useOnce from '../../hooks/useOnce';
import useActionsColumn from '../../components/DataGrid/useActions';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  getTestProps,
  setInitialGridOptions,
  prepareColumns, generateExportSpacesCsvUrl,
} from '../../lib/helpers';
import { getDefinedColumns } from './definedColumns';

// :: Images
import { HouseIcon, WarningIcon } from '../../images/shapes';
import useToken from '../../hooks/useToken';

const getApiFilters = (filters) => {
  if (!filters) return {};

  const newFilters = {};

  Object.keys(filters).forEach((key) => {
    if (key === 'organization.name') {
      newFilters.organizationName = filters['organization.name'].value;
    } else if (key === 'organization.id') {
      newFilters.organizationId = filters['organization.id'].value;
    } else if (key === 'organization.paymentDueDate') {
      newFilters.organizationPaymentDueDate =
        filters['organization.paymentDueDate'].value;
    } else {
      newFilters[key] = filters[key].value;
    }
  });

  return newFilters;
};

const Spaces = ({ testId }) => {
  const jwt = useToken();
  const SORT_KEY = 'cms.all-spaces-sort';
  const OPTIONS_KEY = 'cms.all-spaces-grid-state';

  const spacesGridContainer = useRef();
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { isRoleAdmin } = useContext(UserContext);

  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [filters, setFilters] = useState({});
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, [SORT_KEY]);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, [OPTIONS_KEY]);

  const topBarButtons = useMemo(() => {
    const buttons = [];

    if (isRoleAdmin) {
      buttons.push({
        label: t('Users.Export'),
        link: generateExportSpacesCsvUrl(jwt),
        key: 'Export',
        ...getTestProps(testId, `export-link`, 'testId'),
      });
    }

    return buttons;
  }, [isRoleAdmin, jwt, t, testId]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'all-spaces',
      menuItemOpen: 'admin',
      topBar: {
        heading: t('Global.AllSpaces'),
        buttons: topBarButtons
      },
    }));
  }, [updateAppContext, t, topBarButtons]);

  useOnce(handlePageUpdate);

  const spacesParams = useMemo(
    () => ({
      page,
      limit,
      ...getApiFilters(filters),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters, sort?.sortOrder, sort?.sortField],
  );

  const allSpacesOptions = useMemo(
    () => ({
      pause: !isRoleAdmin,
    }),
    [isRoleAdmin],
  );

  const {
    data: allSpaces,
    errors: allSpacesError,
    status: allSpacesStatus,
    isLoading: allSpacesAreLoading,
    pagination: allSpacesPagination,
    reload: allSpacesReload,
  } = useAllSpaces(spacesParams, allSpacesOptions);

  useApiErrorsToast(allSpacesError);

  const pagination = useMemo(() => allSpacesPagination, [allSpacesPagination]);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (!allSpacesAreLoading) setFirstLoading(false);
  }, [allSpacesAreLoading, firstLoading]);

  useEffect(() => {
    if (!initDataHasContent && allSpaces.length) {
      setInitDataHasContent(true);
    }
  }, [initDataHasContent, allSpaces.length]);

  const handleResetGrid = useCallback(() => {
    setSort();
    setPage(1);
    setLimit(20);
    setFilters({});
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [OPTIONS_KEY, SORT_KEY]);

  const handlePageChange = useCallback((page) => {
    setPage(page);
  }, []);

  const filtersApplied = useMemo(
    () =>
      filters
        ? Object.keys(filters).filter((key) => filters[key])?.length > 0
        : false,
    [filters],
  );

  const handleLimitChange = useCallback((resultsLimit) => {
    setLimit(resultsLimit);
    setPage(1);
  }, []);

  const handleFilters = useCallback((currentFilters) => {
    setFilters(currentFilters);
    setPage(1);
  }, []);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: '/',
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.AllSpaces'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'Spaces',
        },
      ],
    }));
  }, [t, updateAppContext]);

  const showGrid = useMemo(() => {
    return (
      isRoleAdmin &&
      (allSpaces.length > 0 ||
        (allSpacesAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent)
    );
  }, [
    firstLoading,
    filtersApplied,
    initDataHasContent,
    isRoleAdmin,
    allSpaces.length,
    allSpacesAreLoading,
  ]);

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && isRoleAdmin) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {allSpacesStatus === 403 || !isRoleAdmin
            ? t('Global.NoAccess')
            : t('Media.OnErrorMessage')}
        </div>
      </Heading>
    );
  }, [showGrid, firstLoading, isRoleAdmin, testId, allSpacesStatus, t]);

  const handleInitialGridOptions = useCallback(
    (cols, minWidth) => {
      setInitialGridOptions(
        cols,
        minWidth,
        spacesGridContainer.current.offsetWidth - 50,
        setGridOptions,
        setColumns,
        OPTIONS_KEY,
      );
    },
    [OPTIONS_KEY],
  );

  useEffect(() => {
    prepareColumns(
      gridOptions,
      OPTIONS_KEY,
      editGrid,
      t,
      testId,
      setGridOptions,
      handleInitialGridOptions,
      setColumns,
      getDefinedColumns,
    );
  }, [t, handleInitialGridOptions, gridOptions, testId, editGrid, OPTIONS_KEY]);

  const actionColumn = useActionsColumn('/spaces');

  const allColumns = useMemo(
    () => [actionColumn, ...columns],
    [columns, actionColumn],
  );

  return (
    <div className="w-full md:h-[calc(100vh-66px)]">
      <Helmet>
        <title>{t('Global.Spaces')}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full">
        <div
          className={twMerge(
            'flex flex-col xs:flex-row items-center justify-between relative w-full px-5 lg:px-7',
            'space-y-3 xs:space-y-0',
            'bg-slate-50 mb-7 py-3 xs:py-2 border-b dark:bg-gray-900 dark:border-b dark:border-slate-800',
          )}
        >
          <DataGridControl
            displayGridCheckboxes={showGrid && columns.length > 0}
            displayGridControl={showGrid}
            editGrid={editGrid}
            filterGrid={filterGrid}
            setEditGrid={setEditGrid}
            setFilterGrid={setFilterGrid}
            handleResetGrid={handleResetGrid}
            limit={limit}
            handleLimitChange={handleLimitChange}
            columns={columns}
            gridOptions={gridOptions}
            optionsLocalStorageKey={OPTIONS_KEY}
            setGridOptions={setGridOptions}
            columnsVisibilityAdditionalClasses={twMerge(
              '-top-2 xs:top-[0.2rem]',
            )}
            testId={testId}
          />
        </div>
        <div className="px-5 lg:px-7 rounded-lg w-full h-full">
          <div
            className="h-[calc(100vh-230px)] md:h-[calc(100%-150px)] w-full"
            ref={spacesGridContainer}
          >
            {showGrid ? (
              <CustomizableDataGrid
                columns={allColumns}
                setColumns={setColumns}
                data={allSpaces}
                isLoading={allSpacesAreLoading}
                setSort={setSort}
                sort={sort?.sortField || undefined}
                sortOrder={sort?.sortOrder || undefined}
                sortingLocalStorageKey={SORT_KEY}
                optionsLocalStorageKey={OPTIONS_KEY}
                setCurrentPage={setPage}
                editGrid={editGrid}
                gridOptions={gridOptions}
                setGridOptions={setGridOptions}
                noDataInfoText={t('Spaces.FiltersEmptyResult')}
                hasFilters={filterGrid}
                filters={filters}
                disableFilters={editGrid}
                onFilter={handleFilters}
                statusBar={
                  <StatusBar
                    rows={pagination.count}
                    currentPage={page}
                    pagesCount={pagination.total_pages}
                    handlePageChange={handlePageChange}
                    handleDataUpdate={allSpacesReload}
                    resultsFrom={(pagination.current_page - 1) * limit + 1}
                    resultsTo={
                      (pagination.current_page - 1) * limit + pagination.count
                    }
                    resultsTotalCount={pagination.total_count}
                    {...getTestProps(testId, 'statusbar', 'testId')}
                  />
                }
                additionalClasses="bg-white dark:bg-gray-900"
                {...getTestProps(testId, 'grid', 'testId')}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                {emptyData}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spaces;

Spaces.propTypes = {
  /**
   * Test id for users page
   */
  testId: PropTypes.string,
};

Spaces.defaultProps = {
  testId: '',
};
