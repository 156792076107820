import { useCallback, useState, useContext, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';

// :: Context
import { ModalProvider } from '../../contexts/ModalContext';
import AppContext from '../../contexts/AppContext';

// :: Hooks
import { useUser } from '../../hooks/api';
import useDarkMode from '../../hooks/useDarkMode';
import useToken from '../../hooks/useToken';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import useSpace from '../../hooks/useSpace';

// :: Components
import Loader from '../../components/Loader/Loader';

// :: Lib
import { putUserChangeDefaultSpace } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import { getTestProps, handleRedirectUrl } from '../../lib/helpers';

// :: Images
import { FlotiqLogo } from '../../images/shapes';

const NavigatePage = ({ testId, children }) => {
  const [darkMode] = useDarkMode();
  const navigate = useNavigate();
  const { spaceSlug } = useParams();
  const { appContext, updateAppContext } = useContext(AppContext);
  const [user, , { removeItem }] = useLocalStorageState('cms.user');
  const jwt = useToken();
  const { space } = useSpace();
  const { t, i18n } = useTranslation();
  const [isLoaderPage, setIsLoaderPage] = useState(true);

  const [searchParams] = useSearchParams();
  const redirect_uri = searchParams.get('redirect_uri');
  const response_type = searchParams.get('response_type');

  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : 'pl');
  }, [i18n.language]);

  const { entity: userData } = useUser(user?.data?.id);

  const handleUpdateSpaceContext = useCallback(
    (id, slug) => {
      updateAppContext?.((prevState) => ({
        ...prevState,
        space: id,
        spaceSlug: slug,
      }));
    },
    [updateAppContext],
  );

  const handleChangeSpace = useCallback(
    async (userID, newSpace) => {
      if (userID && newSpace?.id) {
        setIsLoaderPage(true);
        try {
          const { body, status } = await putUserChangeDefaultSpace(jwt, space, {
            id: userID,
            spaceId: newSpace.id,
          });

          checkResponseStatus(body, status);
        } catch (error) {
          toast.error(error.message);
        }

        setIsLoaderPage(false);
      }
    },
    [jwt, space],
  );

  const debouncedHandleChangeSpace = useDebounceCallback(
    handleChangeSpace,
    250,
  );

  const handleLoggedUser = useCallback(
    (removeLoader) => {
      if (!user) {
        let currentLocation = window.location.href;
        if (currentLocation.includes('/login')) {
          return;
        }

        const publicUrl = (
          process.env.PUBLIC_URL || window.location.origin
        ).replace(/\/{0,10}$/, '');
        if (currentLocation.replace(/\/{0,10}$/, '') === publicUrl) {
          currentLocation = '';
        }

        navigate(
          !currentLocation
            ? '/login'
            : `/login?redirect_uri=${currentLocation}`,
        );
      }

      // Case: user data in local storage is saved before change with spaces
      else if (user?.data?.limits_plan?.free_space_limit === undefined) {
        toast.error(t('Global.UserWasLogoutNewSystemVersion'));
        removeItem();
        navigate('/logout');
        return false;
      }

      // Case: remove loader on recheck logged handler from useEffect
      else if (removeLoader) {
        setTimeout(() => {
          setIsLoaderPage(false);
        }, 1000);
      }

      return true;
    },
    [user, navigate, t, removeItem],
  );

  const handleEntranceInSpace = useCallback(async () => {
    const validLoggedUser = handleLoggedUser();
    if (!validLoggedUser) return;

    if (userData && spaceSlug) {
      const existSpace = userData?.spaces?.filter(
        (el) => el.slug === spaceSlug,
      );
      const currentSpaceId = existSpace?.[0]?.id;
      const currentSpaceSlug = existSpace?.[0]?.slug;

      if (currentSpaceId && appContext?.space === currentSpaceId) return;

      setIsLoaderPage(true);

      handleUpdateSpaceContext(currentSpaceId, currentSpaceSlug);

      if (!currentSpaceId) {
        toast.error(t('Spaces.ErrorWrongSpaceIdRedirectToDefault'));
        navigate('/');
      } else {
        debouncedHandleChangeSpace(userData.id, existSpace[0]);

        TagManager.dataLayer({
          dataLayer: {
            space_id: currentSpaceId,
            plan_id: existSpace[0]?.planLimits?.id,
            plan_name: existSpace[0]?.planLimits?.name,
          },
        });
      }
    } else if (userData?.defaultSpace?.slug) {
      navigate(`/s/${userData?.defaultSpace?.slug}`);
    }

    if (userData?.spaces?.length === 0) {
      setIsLoaderPage(false);

      if (window.location.pathname === '/') {
        navigate('/start');
      }
    }
  }, [
    handleLoggedUser,
    userData,
    spaceSlug,
    appContext?.space,
    handleUpdateSpaceContext,
    t,
    navigate,
    debouncedHandleChangeSpace,
  ]);

  useEffect(() => {
    if (userData) {
      handleEntranceInSpace();
    } else {
      handleLoggedUser(true);
    }
  }, [userData, handleLoggedUser, handleEntranceInSpace]);

  const LoaderPage = useCallback(() => {
    return (
      <div
        className={twMerge(
          'w-full h-screen flex flex-col justify-center items-center',
          darkMode && 'dark:bg-gray-900',
        )}
        {...getTestProps(testId, 'loading')}
      >
        <div className="inline-flex items-center justify-center">
          <FlotiqLogo className={'h-9 m-6'} />
        </div>

        <Loader type="spinner-grid" />
      </div>
    );
  }, [darkMode, testId]);

  const currentChildren = children ? children : <Outlet />;

  if (redirect_uri && user) {
    return handleRedirectUrl(redirect_uri, response_type, user);
  }

  return (
    <ModalProvider>
      {isLoaderPage ? <LoaderPage /> : currentChildren}
    </ModalProvider>
  );
};

export default NavigatePage;
