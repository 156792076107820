import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';
import Switch from '../Switch/Switch';
import { getTestProps } from '../../lib/helpers';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';

const OverrideSwitch = ({ name, readonly, checked, unique, testId }) => {
  const { t } = useTranslation();
  const { isPatchable, editedCount, setOverriddenFields } = useContext(
    ContentObjectFormContext,
  );
  if (!isPatchable || name.includes('].')) {
    return null;
  }
  let tooltipText = null;
  if (unique && editedCount > 1) {
    tooltipText = t('MassEdit.Unique');
  }
  if (readonly) {
    tooltipText = t('MassEdit.ReadOnly');
  }
  return (
    <div className="min-w-[100px] flex justify-center items-start">
      <Tooltip
        tooltip={tooltipText}
        tooltipPlacement="bottomCenter"
        phoneTooltipPlacement="bottomCenter"
        additionalTooltipClasses={'!max-w-[140px]'}
      >
        <Switch
          label={t('Global.Override')}
          checked={checked}
          onChange={(_, checked) => setOverriddenFields(checked, name)}
          labelUp
          disabled={readonly || (unique && editedCount > 1)}
          {...getTestProps(testId, 'override', 'testId')}
        />
      </Tooltip>
    </div>
  );
};

OverrideSwitch.propTypes = {
  /**
   * Name of the field for which the override switch is rendered
   */
  name: PropTypes.string.isRequired,
  /**
   * Should the tooltip about read only field be shown and switch disabled
   */
  readonly: PropTypes.bool,
  /**
   * Is switch checked
   */
  checked: PropTypes.bool,
  /**
   * Should the tooltip about unique field be shown and switch disabled when more than one object is edited
   */
  unique: PropTypes.bool,
};

OverrideSwitch.defaultProps = {
  name: '',
  readonly: false,
  checked: false,
  onChange: null,
  unique: false,
  testId: '',
};

export default OverrideSwitch;
