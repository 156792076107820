import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Component
import Dropdown from '../../components/Dropdown/Dropdown';

// :: Hooks
import useDebounceCallback from '../../hooks/useDebounceCallback';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import PropTypes from 'prop-types';

const ColumnsVisibility = ({
  columns,
  testId,
  onChange,
  gridOptions,
  additionalClasses,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState([]);

  const currentOptions = columns
    .filter((el) => el.removable)
    .map((el) => ({
      value: el.accessor,
      label: el.label,
    }));

  const handleCurrentValue = useCallback(() => {
    if (columns.length) {
      const gridOptionsHide = {};
      for (let key in gridOptions) {
        gridOptionsHide[gridOptions[key].colId] = gridOptions[key].hide;
      }

      const res = columns
        .filter((el) => el.removable)
        .map((obj) => obj.accessor)
        .filter((el) => !gridOptionsHide[el]);

      setValue(res);
    }
  }, [columns, gridOptions]);

  useEffect(() => {
    handleCurrentValue();
  }, [handleCurrentValue]);

  const handleChange = useCallback(
    (e) => {
      const selectedColumns = e.target.value;

      setValue(selectedColumns);

      const availableOptions = currentOptions.map((el) => el.value);

      const updateGridOptions = gridOptions?.map((el) => {
        let updateHide = el.hide;
        if (availableOptions.indexOf(el.colId) > -1) {
          if (selectedColumns.indexOf(el.colId) > -1) {
            updateHide = false;
          } else {
            updateHide = true;
          }
        }
        return {
          ...el,
          hide: updateHide,
        };
      });

      onChange?.(updateGridOptions);
    },
    [onChange, gridOptions, currentOptions],
  );

  const debouncedHandleChange = useDebounceCallback(handleChange, 500);

  return (
    <div
      className={twMerge(
        'w-full lg:w-auto absolute inline-flex items-center z-10',
        additionalClasses,
      )}
    >
      <label className="mr-2 text-sm md:text-base dark:text-white">
        {t('Global.Columns')}:
      </label>
      <Dropdown
        onChange={debouncedHandleChange}
        options={currentOptions}
        value={value}
        nullable={true}
        multiple={true}
        placeholder={t('Global.SelectColumns')}
        additionalClasses={'w-auto'}
        additionalContainerClasses={'h-7 md:h-8 w-[250px]'}
        additionalDropdownClasses={`xl:w-auto xl:min-w-full left-0`}
        filterCallback={async (query, options) => {
          return options.filter((option) =>
            (option.label + ' ' + option.value)
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          );
        }}
        {...getTestProps(testId, `columns-remove`, 'testId')}
      />
    </div>
  );
};

ColumnsVisibility.propTypes = {
  /**
   * Additional CSS classes
   */
  additionalClasses: PropTypes.string,
};

ColumnsVisibility.defaultProps = {
  additionalClasses: '',
};

export default ColumnsVisibility;
