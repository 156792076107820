import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// :: Components
import LinkButton from '../../components/LinkButton/LinkButton';
import Heading from '../../components/Heading/Heading';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Panel from '../../components/Panel/Panel';
import CheckCircle from '../../components/CheckCircle/CheckCircle';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Icons
import { PlusCircleOutlineIcon, ArrowRightThinIcon } from '../../images/shapes';

const GetStarted = ({ title, tasks, readMoreText, readMoreLink, testId }) => {
  const { t } = useTranslation();

  return (
    <Panel additionalContainerClasses="py-4 px-4" title={title} open={true}>
      <ProgressBar
        completionPercentValue={
          tasks.length
            ? (tasks.filter((task) => task.done).length / tasks.length) * 100
            : 100
        }
        barBackgroundColor="gradientBackground"
        barThickness="thick"
      />

      <div className="flex-wrap text-black">
        {tasks?.map((task) => (
          <div key={task.key}>
            <div className="flex pt-3 items-center">
              <CheckCircle
                checked={task.done}
                title={t(task.done ? 'Global.Done' : 'Global.ToDo', {
                  taskName: task.name,
                })}
                additionalClasses="h-5 w-5"
                {...getTestProps(testId, task.name, 'testId')}
              />
              <Link
                to={task.link}
                className="underline pl-3 hover:opacity-50 text-sm md:text-base dark:text-white"
                target={task.openInNewTab ? '_blank' : ''}
                rel="noreferrer"
                onClick={task.onClick}
              >
                {task.name}
              </Link>
            </div>

            {task?.description && (
              <div className="flex items-center pl-8 mt-1.5">
                <Heading
                  level={7}
                  additionalClasses="p-0 w-fit font-medium text-sm md:text-base dark:text-white"
                >
                  {task?.description}
                </Heading>
                <PlusCircleOutlineIcon className="w-6 items-center text-blue pl-2" />
              </div>
            )}
          </div>
        ))}
      </div>

      <LinkButton
        buttonColor="borderless"
        noPaddings
        buttonSize="sm"
        iconImage={<ArrowRightThinIcon />}
        iconPosition="end"
        iconColor={'blue'}
        link={readMoreLink}
        additionalClasses="px-0 ml-0 mt-6 w-fit"
        target="_blank"
        rel="noreferrer"
        {...getTestProps(testId, 'documentation', 'testId')}
      >
        {readMoreText}
      </LinkButton>
    </Panel>
  );
};

export default GetStarted;

GetStarted.propTypes = {
  /**
   * Title for your get started panel
   */
  title: PropTypes.string,
  /**
   * List of tasks to do
   */
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.string,
      done: PropTypes.bool,
      openInNewTab: PropTypes.bool,
      onClick: PropTypes.func,
      key: PropTypes.string.isRequired,
    }),
  ),
  /**
   * Read more link text
   */
  readMoreText: PropTypes.string,
  /**
   * Read more link path
   */
  readMoreLink: PropTypes.string,
};

GetStarted.defaultProps = {
  title: '',
  tasks: [],
  readMoreText: '',
  readMoreLink: '',
};
