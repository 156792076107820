import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Components
import Tile from './Tile';

// :: Images
import primaryBg from '../../images/graphics/tile-backgrounds/tile_bg_1-dark.svg';
import secondaryBg from '../../images/graphics/tile-backgrounds/tile_bg_2-dark.svg';

const Tiles = ({ items, layout, additionalClasses, testId }) => {
  const layoutClasses = {
    none: 'space-y-5',
    grid: 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[repeat(auto-fit,_minmax(10rem,_1fr))] gap-5',
    flex: 'flex flex-wrap lg:flex-nowrap lg:space-x-5 space-y-5 lg:space-y-0',
  };

  return (
    <div className={twMerge('z-10', layoutClasses[layout], additionalClasses)}>
      {items?.map((tileItem, index) => {
        const { bg, ...props } = tileItem;
        return (
          <Tile
            key={`tile-${tileItem.title}`}
            bg={bg || (index % 2 === 0 ? primaryBg : secondaryBg)}
            additionalClasses="odd:bg-gradient-blue even:bg-gradient-green"
            testId={testId}
            {...getTestProps(testId, `tile-${tileItem.title}`)}
            {...props}
          />
        );
      })}
    </div>
  );
};

export default Tiles;

Tiles.propTypes = {
  /**
   * Tiles items
   */
  items: PropTypes.any,
  /**
   * Tiles layout
   */
  layout: PropTypes.oneOf(['none', 'grid', 'flex']),
  /**
   * Tiles additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Tiles test id
   */
  testId: PropTypes.string,
};

Tiles.defaultProps = {
  items: [],
  layout: 'flex',
  additionalClasses: '',
  testId: '',
};
