import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// :: Components
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import Dropdown from '../../components/Dropdown/Dropdown';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';

// :: Helpers
import { getTestProps } from '../../lib/helpers';
import { twMerge } from 'tailwind-merge';

const UserRolesForm = ({
  roles,
  onSubmit,
  rolesOptions,
  filterRoles,
  additionalClasses,
  testId,
  spaceName,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  let validationSchema = useMemo(
    () =>
      yup.object({
        roles: yup.array().of(yup.string()),
      }),
    [],
  );

  const onCancel = useCallback(
    (formik) => formik.resetForm({ values: { roles } }),
    [roles],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      setIsLoading(true);
      const errors = await onSubmit(values.roles);
      setErrors(errors);
      if (!errors) formik.resetForm({ values });
      setIsLoading(false);
    },
    [onSubmit],
  );

  return (
    <Formik
      initialValues={{ roles }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          className={twMerge(additionalClasses)}
          id={`user-roles-form-${spaceName}`}
          onSubmit={formik.handleSubmit}
          noValidate={true}
        >
          <div className="flex flex-col gap-4">
            <div className="mr-2 font-bold text-lg dark:text-white">
              {t('Global.UserRoles')}
              <span {...getTestProps(testId, 'space-name')}>: {spaceName}</span>
            </div>
            <div className="flex items-center gap-4 md:gap-6 w-full">
              <Dropdown
                name="roles"
                placeholder={t('UserRoles.AssignRole')}
                options={rolesOptions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.roles}
                disabled={isLoading}
                error={formik.errors.roles || formik.status?.errors?.roles}
                filterCallback={filterRoles}
                renderEmpty={() => (
                  <div className="p-2 text-slate-400/80 text-sm text-center">
                    {t('Global.NoData')}
                  </div>
                )}
                emptyOptions={
                  <div className="p-2 text-slate-400/80 text-sm text-center">
                    {t('Global.NoData')}
                  </div>
                }
                debounceTime={300}
                additionalClasses="w-full"
                multiline
                multiple
                truncateLength={50}
                {...getTestProps(testId, 'roles', 'testId')}
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-start justify-between gap-2">
                <Button
                  type="submit"
                  form={`user-roles-form-${spaceName}`}
                  buttonSize="xs"
                  disabled={isLoading}
                  iconImage={
                    isLoading ? (
                      <Loader size="small" type="spinner-grid" />
                    ) : null
                  }
                  additionalClasses="w-fit whitespace-nowrap"
                  {...getTestProps(testId, 'submit', 'testId')}
                >
                  {t('Global.Save')}
                </Button>
                {formik.dirty && (
                  <Button
                    buttonColor="gray"
                    buttonSize="xs"
                    onClick={() => onCancel(formik)}
                    disabled={isLoading}
                    {...getTestProps(testId, 'cancel', 'testId')}
                  >
                    {t('Global.Cancel')}
                  </Button>
                )}
              </div>
              {errors && (
                <div
                  className="text-red text-sm whitespace-pre-line"
                  {...getTestProps(testId, 'errors')}
                >
                  {errors}
                </div>
              )}
            </div>
          </div>
          <DirtyHandler />
        </form>
      )}
    </Formik>
  );
};

export default UserRolesForm;

UserRolesForm.propTypes = {
  /**
   * Current headles roles assign to user
   */
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * All headlles roles data
   */
  rolesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  /**
   * User roles filter callback
   */
  filterRoles: PropTypes.func,
  /**
   *
   */
  additionalClasses: PropTypes.string,
  /**
   * Form test id
   */
  testId: PropTypes.string,
  /**
   * User roles space name
   */
  spaceName: PropTypes.string,
};

UserRolesForm.defaultProps = {
  roles: [],
  rolesOptions: [],
  filterRoles: /* istanbul ignore next */ () => {},
  additionalClasses: '',
  testId: '',
  spaceName: '',
};
