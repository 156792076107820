import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

// :: Components
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const Divider = () => (
  <div className="h-0 border border-slate-200  dark:border-slate-800 w-full mt-6 md:mt-10 mb-3 md:mb-8" />
);

const ErrorBox = ({ error }) => {
  if (!error) return null;
  return (
    <div className="text-red py-2 inline-flex items-center">
      <div className="block text-left">{error}</div>
    </div>
  );
};

const UserActions = ({
  user,
  onSubscriptionChange,
  onDelete,
  onPasswordRequest,
  testId,
  deletable,
  isSectionToken,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const [subLoading, setSubLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sendingPswdReq, setSendingPswdReq] = useState(false);
  const [deleteLinkSent, setDeleteLinkSent] = useState(false);
  const [pswdLinkSent, setPswdLinkSent] = useState(false);

  const apiToken = useMemo(
    () => user?.apiKeys?.find((key) => key.global && key.canUpdate),
    [user.apiKeys],
  );

  const apiReadOnlyToken = useMemo(
    () => user?.apiKeys?.find((key) => key.global && !key.canUpdate),
    [user.apiKeys],
  );

  const handleSubscription = useCallback(async () => {
    setSubLoading(true);
    const error = await onSubscriptionChange(
      user.subscribed,
      user.email,
      btoa(user.id),
    );
    setErrors((prevErrors) => ({ ...prevErrors, subscription: error }));
    setSubLoading(false);
  }, [onSubscriptionChange, user]);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    const error = await onDelete(user.email);
    if (!error) setDeleteLinkSent(true);
    setErrors((prevErrors) => ({ ...prevErrors, delete: error }));
    setIsDeleting(false);
  }, [onDelete, user.email]);

  const handlePswdChange = useCallback(async () => {
    setSendingPswdReq(true);
    const error = await onPasswordRequest(user.email);
    if (!error) setPswdLinkSent(true);
    setErrors((prevErrors) => ({ ...prevErrors, password: error }));
    setSendingPswdReq(false);
  }, [onPasswordRequest, user.email]);

  const boxClasses = 'flex flex-col gap-4 md:gap-6 max-w-3xl dark:text-white';
  const headerClasses =
    'text-xl md:text-2xl xl:text-3xl font-semibold dark:text-white';

  return (
    <>
      {isSectionToken && (
        <>
          <div className={boxClasses}>
            <div className={headerClasses}>{t('UserForm.Token')}</div>
            <div className="grid grid-cols-1 xs:grid-cols-2 items-center gap-4 md:gap-6 w-full">
              <Input
                name="apiToken"
                type="password"
                label={t('UserForm.APIToken')}
                value={apiToken?.apiKey}
                additionalInputClasses={'!bg-white dark:!bg-gray-900'}
                additionalClasses={twMerge(!apiToken && 'hidden')}
                disabled
                {...getTestProps(testId, 'api-token', 'testId')}
              />
              <Input
                name="apiReadonly"
                type="password"
                label={t('UserForm.APIReadOnly')}
                value={apiReadOnlyToken?.apiKey}
                additionalInputClasses="!bg-white dark:!bg-gray-900"
                additionalClasses={twMerge(!apiReadOnlyToken && 'hidden')}
                disabled
                {...getTestProps(testId, 'api-readonly', 'testId')}
              />
            </div>

            <Link
              to="/api-keys"
              className="underline text-blue-600 hover:text-blue"
            >
              {t('UserForm.ManageKeys')}
            </Link>
          </div>
          <Divider />
        </>
      )}

      <div className={boxClasses}>
        <div className={headerClasses}>{t('UserForm.EmailNotification')}</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 items-center">
          <Input
            name="email"
            placeholder={t('Global.Email')}
            label={t('Global.Email')}
            value={user.email}
            additionalInputClasses="!bg-white dark:!bg-gray-900"
            disabled
          />
          <div className="md:mt-5 dark:text-white">
            <Trans i18nKey="UserForm.ChangeEmailInfo">
              <Link
                className="whitespace-nowrap text-blue-600 underline cursor-pointer"
                to="mailto:hello@flotiq.com"
                target="_blank"
                rel="noreferrer"
              >
                hello@flotiq.com
              </Link>
            </Trans>
          </div>
        </div>
        <div>
          <Button
            buttonSize="sm"
            disabled={subLoading}
            iconImage={
              subLoading ? <Loader size="small" type="spinner-grid" /> : null
            }
            onClick={handleSubscription}
            additionalClasses="w-fit"
            {...getTestProps(testId, 'subscription', 'testId')}
          >
            {user.subscribed
              ? t('UserForm.Unsubscribe')
              : t('UserForm.Subscribe')}
          </Button>
          <ErrorBox error={errors.subscription} />
        </div>
      </div>
      <Divider />
      <div className={boxClasses}>
        <div className={headerClasses}>{t('Global.Password')}</div>
        {t('UserForm.ChangePasswordInfo')}
        <div>
          <Button
            buttonSize="sm"
            disabled={sendingPswdReq || pswdLinkSent}
            iconImage={
              sendingPswdReq ? (
                <Loader size="small" type="spinner-grid" />
              ) : null
            }
            onClick={handlePswdChange}
            additionalClasses="w-fit"
            {...getTestProps(testId, 'change-password', 'testId')}
          >
            {t('UserForm.ChangePassword')}
          </Button>
          <ErrorBox error={errors.password} />
        </div>
      </div>

      {deletable && (
        <>
          <Divider />
          <div className={boxClasses}>
            <div className={headerClasses}>{t('UserForm.DeleteAccount')}</div>
            {t('UserForm.DeleteAccountInfo')}
            <div>
              <Button
                buttonSize="sm"
                disabled={isDeleting || deleteLinkSent}
                iconImage={
                  isDeleting ? (
                    <Loader size="small" type="spinner-grid" />
                  ) : null
                }
                onClick={handleDelete}
                additionalClasses="w-fit"
                {...getTestProps(testId, 'delete-user', 'testId')}
              >
                {t('UserForm.DeleteAccount')}
              </Button>
              <ErrorBox error={errors.delete} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserActions;

UserActions.propTypes = {
  /**
   * User data
   */
  user: PropTypes.shape({
    activationToken: PropTypes.string,
    email: PropTypes.string,
    subscribed: PropTypes.bool,
    apiKeys: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  /**
   * On subscription change callback
   */
  onSubscriptionChange: PropTypes.func.isRequired,
  /**
   * On delete user callback
   */
  onDelete: PropTypes.func.isRequired,
  /**
   * On password request send callback
   */
  onPasswordRequest: PropTypes.func.isRequired,
  /**
   * User actions test id
   */
  testId: PropTypes.string,
  /**
   * User can be deleted
   */
  deletable: PropTypes.bool,
  /**
   * Section Token visibility
   */
  isSectionToken: PropTypes.bool,
};

UserActions.defaultProps = {
  deletable: true,
  testId: '',
  isSectionToken: true,
};
