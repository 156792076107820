import { Trans, useTranslation } from 'react-i18next';
import {
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
} from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// :: Components
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import Dropdown from '../../components/Dropdown/Dropdown';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import Heading from '../../components/Heading/Heading';
import LinkButton from '../../components/LinkButton/LinkButton';

// :: Helpers
import { getStripeURL, getTestProps } from '../../lib/helpers';
import { emailSchema, passwordSchema } from '../../lib/yupHelpers';
import { manageSubscription } from '../../lib/flotiq-client/api-helpers';

// :: Hooks
import { useSpacesList } from '../../hooks/api';
import useToken from '../../hooks/useToken';

// :: Images
import { PolandFlagIcon, UnitedKingdomFlagIcon } from '../../images/shapes';

// :: Contexts
import UserContext from '../../contexts/UserContext';

const UserForm = forwardRef(
  ({ user, onSubmit, mode, withDirtyHandler, testId, owner }, ref) => {
    const { t } = useTranslation();
    const jwt = useToken();
    const { isRoleAdmin, isAdmin } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const submitButtonRef = useRef();

    const { data: spacesData, isLoading: isLoadingSpacesData } =
      useSpacesList();

    useImperativeHandle(ref, () => ({
      submit() {
        submitButtonRef.current.click();
      },
    }));

    const validationSchema = useMemo(
      () =>
        mode === 'add'
          ? yup.object({
              firstName: yup.string().required(t('Form.FormErrorNotBlank')),
              lastName: yup.string().required(t('Form.FormErrorNotBlank')),
              email: emailSchema(t),
              plainPassword: passwordSchema(t),
              language: yup.string().required(t('Form.FormErrorNotBlank')),
              spaces: yup.array(),
              roles: yup
                .array()
                .min(1, t('PropertyForm.Errors.OptionsLength'))
                .required(t('PropertyForm.Errors.OptionsLength')),
            })
          : yup.object({
              firstName: yup.string().required(t('Form.FormErrorNotBlank')),
              lastName: yup.string().required(t('Form.FormErrorNotBlank')),
              username: yup.string().required(t('Form.FormErrorNotBlank')),
              language: yup.string().required(t('Form.FormErrorNotBlank')),
              spaces: yup.array(),
              roles: yup
                .array()
                .min(1, t('PropertyForm.Errors.OptionsLength'))
                .required(t('PropertyForm.Errors.OptionsLength')),
            }),
      [mode, t],
    );

    const organizationsDefaultSpace = useMemo(
      () =>
        spacesData
          ?.filter((space) => space.defaultSpace)
          ?.map((space) => space.id),
      [spacesData],
    );

    const initialValues = useMemo(
      () =>
        mode === 'add'
          ? {
              email: '',
              plainPassword: '',
              firstName: '',
              lastName: '',
              language: 'en',
              spaces: organizationsDefaultSpace,
              roles: ['ROLE_HEADLESS_USER'],
            }
          : {
              firstName: user?.firstName,
              lastName: user?.lastName,
              username: user?.username,
              language: user?.language,
              spaces: user?.spaces?.map((space) => space.id),
              roles: user?.roles,
              ...(isRoleAdmin
                ? { paymentClientId: user?.paymentClientId }
                : {}),
            },
      [mode, organizationsDefaultSpace, user, isRoleAdmin],
    );

    let buttonSaveText =
      mode === 'add' ? t('Global.Save') : t('Global.SaveChanges');

    const onCancel = useCallback(
      (formik) => formik.resetForm({ values: initialValues }),
      [initialValues],
    );

    const handleSubmit = useCallback(
      async (values, formik) => {
        setIsLoading(true);

        const { spaces, ...otherValues } = values;

        const errors = await onSubmit({
          ...otherValues,
          ...(isAdmin && {
            spaces: spaces?.map((spaceId) => ({ id: spaceId })),
          }),
        });

        formik.setStatus({ ...formik.status, errors });
        if (!errors || !Object.keys(errors).length)
          formik.resetForm({ values });
        setIsLoading(false);
      },
      [onSubmit, isAdmin],
    );

    const lngOptions = useMemo(
      () => [
        {
          value: 'en',
          searchString: t('UserForm.English'),
          label: (
            <div className="flex flex-row items-center">
              <UnitedKingdomFlagIcon className="w-5 h-5 mr-2" />
              {t('UserForm.English')}
            </div>
          ),
        },
        {
          value: 'pl',
          searchString: t('UserForm.Polish'),
          label: (
            <div className="flex flex-row items-center">
              <PolandFlagIcon className="w-5 h-5 mr-2" />
              {t('UserForm.Polish')}
            </div>
          ),
        },
      ],
      [t],
    );

    const spacesOptions = useMemo(() => {
      if (isAdmin) {
        return (
          spacesData?.map(({ id, name }) => ({ value: id, label: name })) || []
        );
      } else {
        return (
          user?.spaces?.map(({ id, name }) => ({ value: id, label: name })) ||
          []
        );
      }
    }, [spacesData, user, isAdmin]);

    const rolesOptions = useMemo(
      () => [
        { value: 'ROLE_HEADLESS_USER', label: 'Organization User' },
        { value: 'ROLE_HEADLESS_ADMIN', label: 'Organization Admin' },
        ...(isRoleAdmin
          ? [{ value: 'ROLE_ADMIN', label: 'Flotiq Admin' }]
          : []),
      ],
      [isRoleAdmin],
    );

    const spaceLinks = useMemo(
      () =>
        user?.spaces?.length > 0
          ? user.spaces.map(({ id, name }, idx) => (
              <LinkButton
                key={id}
                link={`/spaces/edit/${id}`}
                target="_blank"
                rel="noreferrer"
                buttonSize={'sm'}
                buttonColor={'borderless'}
                additionalClasses={'inline font-normal 2xl:text-sm'}
                noPaddings
              >
                {name} ↗{idx < user.spaces.length - 1 ? ', ' : ''}
              </LinkButton>
            ))
          : null,
      [user?.spaces],
    );

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
      >
        {(formik) => (
          <form
            id="user-form"
            className="max-w-3xl"
            onSubmit={formik.handleSubmit}
            noValidate={true}
            autoComplete="off"
          >
            <div className="flex flex-col gap-4 md:gap-6">
              <div className="text-xl md:text-2xl xl:text-3xl font-semibold dark:text-white">
                {t('UserForm.PersonalInformation')}
              </div>
              <div className="grid grid-cols-1 xs:grid-cols-2 items-center gap-4 md:gap-6 w-full">
                {mode === 'edit' && (
                  <Input
                    autoComplete="off"
                    name={'username'}
                    placeholder={t('UserForm.Username')}
                    label={t('UserForm.Username')}
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.username || formik.status?.errors?.username
                    }
                    additionalInputClasses={
                      isLoading ? '' : '!bg-white dark:!bg-gray-900'
                    }
                    disabled
                    required
                  />
                )}

                {mode === 'add' && (
                  <>
                    <Input
                      autoComplete="off"
                      name={'email'}
                      placeholder={'Email'}
                      label={'Email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.email || formik.status?.errors?.email
                      }
                      required
                      additionalInputClasses={
                        isLoading ? '' : '!bg-white dark:!bg-gray-900'
                      }
                      {...getTestProps(testId, 'email', 'testId')}
                    />

                    <Input
                      autoComplete="new-password"
                      name="plainPassword"
                      type="password"
                      placeholder={t('Global.Password')}
                      label={t('Global.Password')}
                      value={formik.values.plainPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.plainPassword ||
                        formik.status?.errors?.plainPassword
                      }
                      required
                      showPasswordInfo
                      additionalInputClasses={
                        isLoading ? '' : '!bg-white dark:!bg-gray-900'
                      }
                      {...getTestProps(testId, 'plain-password', 'testId')}
                    />
                  </>
                )}

                <Input
                  name="firstName"
                  placeholder={t('Global.FirstName')}
                  label={t('Global.FirstName')}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.firstName || formik.status?.errors?.firstName
                  }
                  disabled={isLoading}
                  additionalClasses="xs:row-start-2"
                  required
                  {...getTestProps(testId, 'first-name', 'testId')}
                />

                <Input
                  name="lastName"
                  placeholder={t('Global.LastName')}
                  label={t('Global.LastName')}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.lastName || formik.status?.errors?.lastName
                  }
                  disabled={isLoading}
                  additionalClasses="xs:row-start-2"
                  required
                  {...getTestProps(testId, 'last-name', 'testId')}
                />

                <Dropdown
                  name="language"
                  label={t('UserForm.Language')}
                  options={lngOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.language}
                  disabled={isLoading}
                  error={
                    formik.errors.language || formik.status?.errors?.language
                  }
                  additionalClasses="xs:row-start-3"
                  hideSearch
                  required
                />
              </div>

              <div className="flex flex-col">
                <Heading level={4} additionalClasses="mt-1 dark:text-white">
                  {t('Spaces.SpacesAndRoles')}
                </Heading>
              </div>

              <div className="grid grid-cols-1 xs:grid-cols-2 items-center w-full">
                {isAdmin && isLoadingSpacesData ? (
                  <div className="h-[110px] flex justify-center items-center">
                    <Loader size="small" type="spinner-grid" />
                  </div>
                ) : (
                  <Dropdown
                    name="spaces"
                    label={t('Global.Spaces')}
                    options={spacesOptions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.spaces}
                    disabled={isLoading || !isAdmin}
                    error={
                      formik.errors.spaces || formik.status?.errors?.spaces
                    }
                    helpText={
                      isRoleAdmin && spaceLinks ? (
                        <div className="inline-flex flex-wrap gap-1 mt-1">
                          <Trans i18nKey="UserForm.SpaceLinks">
                            Go to space:
                            <span>{spaceLinks}</span>
                          </Trans>
                        </div>
                      ) : (
                        ''
                      )
                    }
                    additionalClasses="xs:row-start-5 min-h-[110px]"
                    hideSearch
                    truncateLength={30}
                    multiline
                    multiple
                    {...getTestProps(testId, 'spaces', 'testId')}
                  />
                )}

                <Dropdown
                  name="roles"
                  label={t('UserForm.OrganizationRole')}
                  options={rolesOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.roles}
                  disabled={isLoading || !isAdmin}
                  error={formik.errors.roles || formik.status?.errors?.roles}
                  additionalClasses="xs:row-start-6 mt-3"
                  hideSearch
                  truncateLength={30}
                  multiline
                  multiple
                  required
                  {...getTestProps(testId, 'roles', 'testId')}
                />
              </div>

              {isRoleAdmin && !owner && mode === 'edit' && (
                <>
                  <Heading level={4} additionalClasses="mt-0 dark:text-white">
                    Admin
                  </Heading>

                  <div className="grid grid-cols-1 xs:grid-cols-2 items-center w-full">
                    <Input
                      name="paymentClientId"
                      label={t('Users.PaymentClientId')}
                      value={formik.values.paymentClientId || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.paymentClientId ||
                        formik.status?.errors?.paymentClientId
                      }
                      disabled={isLoading}
                      helpText={
                        <div className="mt-1">
                          <Trans i18nKey="UserForm.PaymentLinks">
                            See
                            <Button
                              buttonSize={'sm'}
                              buttonColor={'borderless'}
                              additionalClasses={
                                'inline font-normal 2xl:text-sm'
                              }
                              onClick={() => manageSubscription(jwt, t)}
                              noPaddings
                            >
                              Stripe manage panel
                            </Button>
                            <LinkButton
                              link={getStripeURL(
                                `/customers/${user?.paymentClientId || ''}`,
                              )}
                              target="_blank"
                              rel="noreferrer"
                              buttonSize={'sm'}
                              buttonColor={'borderless'}
                              additionalClasses={
                                'inline font-normal 2xl:text-sm'
                              }
                              noPaddings
                            >
                              Stripe customer panel
                            </LinkButton>
                          </Trans>
                        </div>
                      }
                    />
                  </div>
                </>
              )}

              <div>
                <div className="flex flex-row items-center gap-2 md:gap-6">
                  <Button
                    buttonSize="sm"
                    type="submit"
                    disabled={isLoading}
                    iconImage={
                      isLoading ? (
                        <Loader size="small" type="spinner-grid" />
                      ) : null
                    }
                    additionalClasses="w-fit whitespace-nowrap"
                    ref={submitButtonRef}
                    {...getTestProps(testId, 'submit', 'testId')}
                  >
                    {buttonSaveText}
                  </Button>
                  {formik.dirty && (
                    <Button
                      buttonColor="gray"
                      buttonSize="sm"
                      onClick={() => onCancel(formik)}
                      disabled={isLoading}
                      {...getTestProps(testId, 'cancel', 'testId')}
                    >
                      {t('Global.Cancel')}
                    </Button>
                  )}
                </div>
                {formik.status?.errors?.global && (
                  <div className="text-red mt-2 inline-flex">
                    <div className="block text-left">
                      {formik.status?.errors?.global}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {withDirtyHandler && <DirtyHandler />}
          </form>
        )}
      </Formik>
    );
  },
);

export default UserForm;

UserForm.propTypes = {
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * User data
   */
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    language: PropTypes.string,
  }),
  /**
   * User form test id
   */
  testId: PropTypes.string,
  /**
   * User mode between "add" or "edit"
   */
  mode: PropTypes.string,
  /**
   * Is profile page
   */
  owner: PropTypes.bool,
};

UserForm.defaultProps = {
  user: {},
  withDirtyHandler: true,
  mode: 'edit',
  testId: '',
  owner: false,
};
