import { useCallback, useContext, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import { ModalProvider } from '../../contexts/ModalContext';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import useSpace from '../../hooks/useSpace';
import { useUser } from '../../hooks/api';

// :: Lib
import { RolePermissions } from '../../lib/rolePermissions';
import { getMaskedOrganizationName } from '../../lib/helpers';

const ADMIN_ROLES = ['ROLE_ADMIN', 'ROLE_HEADLESS_ADMIN'];
const ADMIN_ORGANIZATIONS =
  process.env.REACT_APP_ADMIN_ORGANIZATIONS.split(',');

const WrapUserContext = ({ children }) => {
  const { updateAppContext } = useContext(AppContext);
  const [userContext, setUserContext] = useState({});
  const { space } = useSpace();
  const [user] = useLocalStorageState('cms.user');

  const { entity: userData } = useUser(user?.data?.id);

  const isAdmin = useMemo(
    () =>
      (user?.data?.roles || []).findIndex(
        (role) => ADMIN_ROLES.indexOf(role) > -1,
      ) > -1,
    [user?.data?.roles],
  );

  const isRoleAdmin = useMemo(
    () =>
      (user?.data?.roles || []).findIndex(
        (role) => 'ROLE_ADMIN'.indexOf(role) > -1,
      ) > -1 && ADMIN_ORGANIZATIONS.includes(user?.data?.organization),
    [user?.data?.organization, user?.data?.roles],
  );

  const rolesPermissions = useMemo(() => {
    if (!userData) return;

    const currentPermissions = [];
    (userData?.headlessRoles || []).forEach(({ permissions }) =>
      permissions.forEach((permission) => {
        currentPermissions.push({
          ...permission,
          ctdName: permission.contentTypeDefinition?.name,
        });
      }),
    );
    return currentPermissions;
  }, [userData]);

  const permissions = useMemo(
    () => new RolePermissions(rolesPermissions),
    [rolesPermissions],
  );

  const handleGetUserDetails = useCallback(() => {
    const userDetails = user?.data;
    const token = user?.token;

    updateAppContext?.((prevState) => ({
      ...prevState,
      ...(token && { token }),
      ...(userDetails && { user: userDetails }),
    }));
  }, [user?.data, user?.token, updateAppContext]);

  useOnce(handleGetUserDetails);

  const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

  const userContextValue = useMemo(
    () => ({
      ...userContext,
      updateUserContext: setUserContext,
      permissions,
      isAdmin,
      isRoleAdmin,
      userStorage: user,
      baseUserEventData: {
        user_id: user?.data?.id,
        organization_id: user?.data?.organization,
        organization_name: getMaskedOrganizationName(
          user?.data?.organization_name,
        ),
        plan_id: user?.data?.limits_plan?.id,
        plan_name: user?.data?.limits_plan?.name,
        space_id: space,
      },
    }),
    [isAdmin, isRoleAdmin, permissions, space, user, userContext],
  );

  return (
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <UserContext.Provider value={userContextValue}>
        <ModalProvider>{children ? children : <Outlet />}</ModalProvider>{' '}
      </UserContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default WrapUserContext;
