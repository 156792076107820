import { useCallback, useContext, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

// :: Component
import Heading from '../../components/Heading/Heading';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import HelpErrorTextsTemplate from '../../components/HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import Loader from '../../components/Loader/Loader';
import Switch from '../../components/Switch/Switch';

// :: Component Inner
import SpaceTypeCard from './SpaceTypeCard/SpaceTypeCard';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import useToken from '../../hooks/useToken';
import {
  useAllPublicPlans,
  useConstraints,
  useSpace,
  useUsers,
} from '../../hooks/api';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import { useModals } from '../../contexts/ModalContext';

// :: Lib
import {
  generateRedirectUrl,
  getColorByPlanName,
  getPriceOrContactUs,
  getTestProps,
  sendEventAndClear,
} from '../../lib/helpers';
import {
  checkResponseStatus,
  ResponseError,
} from '../../lib/flotiq-client/response-errors';
import { updateSpacePlan, postSpace } from '../../lib/flotiq-client';
import Dropdown from '../../components/Dropdown/Dropdown';
import StatusPill from '../../components/StatusPill/StatusPill';
import Panel from '../../components/Panel/Panel';
import SpaceLimits from '../SpacesManage/SpaceLimits/SpaceLimits';

const USERS_HOOK_PARAMS = {
  page: 1,
  limit: 10000,
};

const getInitialValues = (spaceData) => {
  return {
    name: spaceData?.name || '',
    planId: spaceData?.planLimits?.id || '',
    planName: spaceData?.planLimits?.name || '',
    planVisibleName: spaceData?.planLimits?.visible_name || '',
    isFreePlan: spaceData?.planLimits?.price === 0 || false,
    users: spaceData?.users?.map((user) => user.id) || [],
  };
};

const SpaceModify = ({ mode, testId, delaySub }) => {
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { isAdmin, userStorage, baseUserEventData } = useContext(UserContext);

  const freeSpaceLimit = userStorage?.data?.limits_plan?.free_space_limit;
  const modal = useModals();
  const jwt = useToken();
  const { id: spaceId } = useParams();
  const navigate = useNavigate();
  const [isSpaceSaving, setIsSpaceSaving] = useState(false);
  const [isPaymentYearly, setIsPaymentYearly] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const usersOptions = useMemo(
    () => ({
      pause: mode !== 'edit',
    }),
    [mode],
  );

  const { data: users, isLoading: usersAreLoading } = useUsers(
    USERS_HOOK_PARAMS,
    usersOptions,
  );

  const constraintsParams = useMemo(
    () => ({
      space: spaceId,
    }),
    [spaceId],
  );

  const constraintsOptions = useMemo(
    () => ({
      pause: !isAdmin,
    }),
    [isAdmin],
  );

  const { entity: spacesFreeCount } = useConstraints(
    'spaces-free',
    constraintsParams,
    constraintsOptions,
  );

  const spaceOptions = useMemo(
    () => ({
      pause: !isAdmin || !spaceId,
    }),
    [isAdmin, spaceId],
  );

  const {
    entity: spaceData,
    updateEntity: updateSpaceData,
    deleteEntity: removeSpaceData,
    errors: spaceDataErrors,
    isLoading: isLoadingSpaceData,
  } = useSpace(spaceId, null, spaceOptions);

  const plansParams = useMemo(
    () => ({
      page: 1,
      limit: 10,
    }),
    [],
  );

  const { data: allPlans, isLoading: isLoadingAllPlans } =
    useAllPublicPlans(plansParams);

  const validationSchema = yup.object({
    name: yup.string().required(t('Form.FormErrorNotBlank')),
    planId: yup
      .string()
      .required(t('Spaces.Form.FormErrorSpaceTypeNotSelected')),
    users: yup.array().of(yup.string()),
  });

  const formik = useFormik({
    initialValues: getInitialValues(mode !== 'add' ? spaceData : undefined),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const newValues = { ...values };
      if (newValues.users?.length) {
        const newUsers = values.users.map((id) => ({ id }));
        newValues.users = newUsers;
      }

      const [[newSpaceData, errors], hasErrors] =
        mode === 'edit'
          ? await handleSpaceEdit(newValues)
          : await handleSpaceCreateUpgrade(newValues);

      formik.setStatus({ ...formik.status, errors });

      if (!hasErrors && formik.resetForm) {
        formik.resetForm({
          values: getInitialValues(newSpaceData),
        });
      }
    },
  });

  const handleSpaceEdit = useCallback(
    async (data) => {
      setIsSpaceSaving(true);
      try {
        const { body, status } = await updateSpaceData(data);

        // Check response status
        checkResponseStatus(body, status);

        // Toast with success on update
        toast.success(t('Spaces.Form.Updated'));

        setIsSpaceSaving(false);

        // Return selected data
        return [[data, {}], false];
      } catch (error) {
        // Error instance
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSpaceSaving(false);
          return [[data, {}], true];
        } else {
          // Toast with error on update space
          toast.error(t('Spaces.Form.CouldntUpdate'));

          setIsSpaceSaving(false);

          // Return selected data and error
          return [[data, error.errors], true];
        }
      }
    },
    [t, updateSpaceData],
  );

  const handleDeleteSpace = useCallback(async () => {
    setIsDeleting(true);

    try {
      const { body, status } = await removeSpaceData({ id: spaceId });

      checkResponseStatus(body, status);
      toast.success(t('Spaces.Form.Removed'));
      setIsDeleting(false);

      sendEventAndClear(
        {
          event: 'space_delete',
          space_id: spaceId,
          plan_id: spaceData?.planLimits?.id,
          plan_name: spaceData?.planLimits?.name,
        },
        baseUserEventData,
      );

      navigate('/organization');
    } catch (error) {
      if (error?.errors?.space?.[0]?.indexOf('has assigned users') > -1) {
        toast.error(t('Spaces.Form.CouldntRemovedHasUsers'));
      } else if (
        error?.errors?.data?.[0]?.includes(
          'Deletion will be possible after the subscription ends',
        )
      ) {
        const endDateGroup = error.errors.data[0].match(
          /until(?<date>[^.\]]*)/,
        )?.groups;
        toast.error(
          t('Spaces.Form.CouldntRemovedSubscription', {
            name: spaceData?.name,
            date: moment(endDateGroup?.date).format('L LT'),
          }),
          { duration: 5000 },
        );
      } else if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(t('Spaces.Form.CouldntRemoved'));
      }

      setIsDeleting(false);
    }

    setIsDeleting(false);
  }, [
    removeSpaceData,
    spaceId,
    t,
    spaceData?.planLimits?.id,
    spaceData?.planLimits?.name,
    spaceData?.name,
    baseUserEventData,
    navigate,
  ]);

  const deleteSpace = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('Spaces.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteSpace(),
    );
    setIsDeleting(false);
  }, [handleDeleteSpace, modal, t]);

  const topBarButtons = useMemo(() => {
    const buttons = [
      {
        key: 'cancel',
        label: t('Global.Cancel'),
        link: '/organization',
        color: 'gray',
        ...getTestProps(testId, 'cancel-type', 'testId'),
      },
    ];

    if (mode === 'edit') {
      buttons.unshift({
        key: 'delete',
        label: t('Global.Delete'),
        onClick: deleteSpace,
        color: 'redBordered',
        iconImage: isDeleting ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          ''
        ),
        disabled: isSpaceSaving || isDeleting || !!spaceDataErrors,
        ...getTestProps(testId, 'delete-space', 'testId'),
      });

      buttons.push({
        key: 'save',
        label: t('Global.Save'),
        iconImage: isSpaceSaving ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          ''
        ),
        disabled: isSpaceSaving || isDeleting || !!spaceDataErrors,
        type: 'submit',
        form: 'space-form',
        ...getTestProps(testId, 'save-space', 'testId'),
      });
    }

    return buttons;
  }, [
    t,
    mode,
    testId,
    isSpaceSaving,
    isDeleting,
    spaceDataErrors,
    deleteSpace,
  ]);

  const handleCreateSpace = useCallback(
    async (data, redirectPath) => {
      try {
        const { body, status } = await postSpace(jwt, undefined, {
          name: data.name,
          planId: data.planId,
          period: isPaymentYearly ? 'year' : 'month',
          uri: generateRedirectUrl(redirectPath),
        });

        checkResponseStatus(body, status);

        setIsSpaceSaving(false);

        if (body.payment_url) {
          window.location.assign(body.payment_url);
        } else {
          toast.success(t('Spaces.Form.Added'));

          sendEventAndClear(
            {
              event: 'space_add',
              space_id: body.id,
              plan_id: data.planId,
              plan_name: data.planName,
            },
            baseUserEventData,
          );

          navigate('/organization');
        }
        return [[body, {}], false];
      } catch (error) {
        setIsSpaceSaving(false);

        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [[data, {}], true];
        }

        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error(t('Spaces.Form.CouldntAdd'));
        }

        return [[data, error.errors], true];
      }
    },
    [baseUserEventData, isPaymentYearly, jwt, navigate, t],
  );

  const handleUpgradeSpace = useCallback(
    async (data, redirectPath) => {
      // Case: remove information modal on change:
      // - from the same plan
      const isChangeForTheSamePlan =
        data.planVisibleName === spaceData?.planLimits?.visible_name;

      if (
        !data.isFreePlan &&
        spaceData?.planLimits?.price !== 0 &&
        !isChangeForTheSamePlan
      ) {
        const confirmation = await modal.confirmation(
          t('Spaces.Form.UpdateInformation', {
            oldPlanName: spaceData?.planLimits?.visible_name,
            newPlanName: data.planVisibleName,
          }),
          t('Global.Information'),
          t('Global.Continue'),
          '',
          'simple',
        );

        if (!confirmation) {
          setIsSpaceSaving(false);
          return;
        }
      }

      try {
        const { body, status } = await updateSpacePlan(jwt, undefined, {
          id: spaceId,
          planId: data.planId,
          period: isPaymentYearly ? 'year' : 'month',
          uri: generateRedirectUrl(redirectPath),
        });
        checkResponseStatus(body, status);

        setIsSpaceSaving(false);

        if (body.status === 'payment_required') {
          window.location.assign(body.url);
        } else {
          if (body.status === 'plan_not_changed') {
            navigate('/organization?cancel=1&type=plan_not_changed');
          } else if (body.status === 'subscription_updated') {
            // Case need time to proces billing and update space
            setIsLoadingPurchase(true);
            setTimeout(() => {
              navigate(redirectPath + '&success=1&type=plan_sub_update');
            }, delaySub);
          } else if (body.status === 'cancel_at_period_end') {
            navigate('/organization?cancel=1&type=plan_period_end');
          }
        }

        return [[data, {}], false];
      } catch (error) {
        setIsSpaceSaving(false);

        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [[data, {}], true];
        }

        toast.error(t('Spaces.Form.CouldntAdd'));

        return [[data, error.errors], true];
      }
    },
    [
      spaceData?.planLimits?.price,
      spaceData?.planLimits?.visible_name,
      modal,
      t,
      jwt,
      spaceId,
      isPaymentYearly,
      navigate,
      delaySub,
    ],
  );

  const handleSpaceCreateUpgrade = useCallback(
    async (data) => {
      setIsSpaceSaving(true);

      let redirectPath = `/organization?action=${mode}&planId=${data.planId}&planName=${data.planName}`;

      if (spaceId) redirectPath += `&spaceId=${spaceId}`;

      const result =
        mode === 'add'
          ? await handleCreateSpace(data, redirectPath)
          : await handleUpgradeSpace(data, redirectPath);

      return result;
    },
    [mode, spaceId, handleCreateSpace, handleUpgradeSpace],
  );

  const handlePageUpdate = useCallback(() => {
    let currentHeading = t('Spaces.CreateANewSpace');
    if (mode === 'upgrade') {
      currentHeading = t('Spaces.UpgradeSpace');
    } else if (mode === 'edit') {
      currentHeading = t('Spaces.EditSpace', { name: '' });
    }

    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'spaces-and-usage',
      topBar: {
        heading: currentHeading,
        buttons: topBarButtons,
      },
      breadcrumbs: [],
    }));
  }, [t, mode, updateAppContext, topBarButtons]);

  useOnce(handlePageUpdate);

  const handleSelected = useCallback((formik, plan) => {
    setSelectedPlan(plan);
    formik.setFieldValue('planId', plan.id);
    formik.setFieldValue('planName', plan.name);
    formik.setFieldValue('planVisibleName', plan.visibleName);
    formik.setFieldValue('isFreePlan', plan.price === 0);
  }, []);

  const sectionHead = useMemo(() => {
    let res = { title: '', subtitle: '' };

    if (mode === 'add') {
      res.title = t('Spaces.CreateASpaceForOrganization', {
        name: userStorage?.data?.organization_name || '',
      });

      res.subtitle = t('Spaces.SpaceDetails');
    } else if (mode === 'upgrade') {
      res.title = `${t('Spaces.UpgradeSpace')} ${spaceData?.name || ''}`;
    } else if (mode === 'edit') {
      res.title = t('Spaces.EditSpace', { name: spaceData?.name });
    }

    return res;
  }, [mode, t, spaceData?.name, userStorage?.data?.organization_name]);

  const isFormVisible = useMemo(() => {
    if (mode === 'add') {
      return !isLoadingAllPlans;
    } else {
      return !isLoadingSpaceData && !spaceDataErrors;
    }
  }, [mode, isLoadingAllPlans, isLoadingSpaceData, spaceDataErrors]);

  const SaveButton = useCallback(() => {
    if (mode === 'edit') return null;

    let buttonConfig = {
      type: 'submit',
      form: 'space-form',
      onClick: undefined,
      text: t('Spaces.Continue'),
      disabled: isSpaceSaving,
    };

    const isContactUsButton = selectedPlan
      ? selectedPlan?.price === -1
      : spaceData?.planLimits?.price === -1;

    if (isContactUsButton) {
      buttonConfig = {
        type: 'button',
        form: '',
        onClick: () =>
          window.open(
            process.env.REACT_APP_FLOTIQ_PAGE_CONTACT,
            '_blank',
            'noopener',
          ),
        text: t('AccountSettings.ContactUs'),
        disabled: isSpaceSaving,
      };
    }

    return (
      <Button
        buttonSize="sm"
        type={buttonConfig.type}
        form={buttonConfig.form}
        additionalClasses="ml-auto"
        iconPosition="start"
        iconImage={
          isSpaceSaving && (
            <Loader
              type="spinner"
              {...getTestProps(testId, 'save-button-loader', 'testId')}
            />
          )
        }
        disabled={buttonConfig.disabled}
        onClick={buttonConfig.onClick}
        {...getTestProps(testId, 'save-button', 'testId')}
      >
        {buttonConfig.text}
      </Button>
    );
  }, [mode, t, testId, spaceData, isSpaceSaving, selectedPlan]);

  const handleFormikSubmit = useCallback(
    (event) => {
      event.preventDefault();
      formik.handleSubmit();
    },
    [formik],
  );

  const userOptions = useMemo(
    () =>
      users?.map((user) => {
        const rolesData = user.roles?.map((role) =>
          role
            .replace('ROLE_HEADLESS_ADMIN', 'Organization Admin')
            .replace('ROLE_HEADLESS_USER', 'Organization User')
            .replace('ROLE_', ''),
        );
        return {
          value: user.id,
          searchString: user.email + ' ' + rolesData?.join(' '),
          label: user.email,
          optionLabel: (
            <span
              className={
                'w-full flex flex-col sm:flex-row flex-wrap gap-1 sm:items-center sm:justify-between mr-1.5'
              }
            >
              {user.email}
              <span className="flex gap-1 ml-auto">
                {rolesData?.map((role) => (
                  <StatusPill key={role} status={role} />
                ))}
              </span>
            </span>
          ),
        };
      }),
    [users],
  );

  const usersDropdownLabel = useMemo(
    () =>
      t('Spaces.AddUsers', {
        value: formik.values.users?.length || 0,
        limit:
          spaceData?.planLimits?.team_members_limit === -1
            ? t('Global.Unlimited')
            : spaceData?.planLimits?.team_members_limit,
      }),
    [formik.values.users?.length, spaceData?.planLimits?.team_members_limit, t],
  );

  const onUsersChange = useCallback(
    (value) => {
      const limit = spaceData?.planLimits?.team_members_limit || 1;
      const newValue = value ? [...value] : [];
      if (limit !== -1 && newValue.length > limit) {
        if (limit === 1) newValue.shift();
        else newValue.pop();
      }
      formik.setFieldValue('users', newValue);
    },
    [formik, spaceData?.planLimits?.team_members_limit],
  );

  const handleUpgrade = useCallback(() => {
    navigate(`/space/upgrade/${spaceId}`);
  }, [navigate, spaceId]);

  return (
    <div className="flex flex-col w-full">
      <div className="grid xl:grid-cols-3 2xl:grid-cols-4 h-full relative mt-7">
        <div
          className={twMerge(
            'relative bg-white dark:bg-slate-950',
            'rounded-lg mx-4 xl:ml-7 xl:mr-7',
            'md:min-h-[80vh] xl:col-span-3 2xl:col-span-4',
            mode === 'edit' &&
              `flex flex-col lg:flex-row gap-10 ${
                isFormVisible
                  ? 'p-4 md:py-6 md:px-10 mb-7 xl:col-span-2 2xl:col-span-3 xl:mr-3.5'
                  : ''
              }`,
          )}
        >
          {(isLoadingAllPlans ||
            (mode !== 'add' && isLoadingSpaceData) ||
            isLoadingPurchase) && (
            <div
              className={twMerge(
                'absolute left-0 top-0 z-10 w-full h-full flex',
                'justify-center items-center bg-white dark:bg-slate-950 rounded-lg opacity-80',
                isLoadingPurchase && 'opacity-100',
              )}
              {...getTestProps(testId, 'loader')}
            >
              <Loader type="spinner-grid" />
            </div>
          )}
          {isFormVisible && (
            <div
              className={twMerge(
                'flex flex-col w-full',
                isSpaceSaving && 'pointer-events-none',
                mode !== 'edit' && 'justify-center p-4 md:py-6 md:px-10',
              )}
              {...getTestProps(testId, 'form-container')}
            >
              <Heading level={3} additionalClasses="py-2 dark:text-white">
                {sectionHead.title}
              </Heading>

              {sectionHead.subtitle && (
                <Heading
                  level={4}
                  additionalClasses="pb-2 pt-4 mt-2 dark:text-white border-t border-slate-200 dark:border-slate-800"
                >
                  {sectionHead.subtitle}
                </Heading>
              )}

              <form
                id="space-form"
                className="w-full"
                onSubmit={handleFormikSubmit}
                noValidate={true}
              >
                {mode === 'add' && (
                  <Heading
                    level={4}
                    additionalClasses="py-2 font-normal text-xl text-md-2xl dark:text-white"
                  >
                    {t('Spaces.ChooseAName')}
                  </Heading>
                )}
                {mode !== 'upgrade' && (
                  <Input
                    name="name"
                    type="text"
                    label={t('Global.Name')}
                    value={formik.values.name}
                    required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.status?.errors?.name || formik.errors.name}
                    additionalClasses={twMerge(
                      'max-w-3xl',
                      mode === 'edit' && 'mt-4 mb-6',
                    )}
                    disabled={isSpaceSaving}
                    {...getTestProps(testId, 'space-name', 'testId')}
                  />
                )}

                {mode === 'edit' && (
                  <Dropdown
                    name="users"
                    options={userOptions}
                    isDataLoading={usersAreLoading}
                    value={formik.values.users}
                    onChange={(_, value) => onUsersChange(value)}
                    onBlur={formik.handleBlur}
                    placeholder={t('Spaces.ChooseUsers')}
                    label={usersDropdownLabel}
                    disabled={isSpaceSaving}
                    error={formik.errors.users || formik.status?.errors?.users}
                    additionalClasses="max-w-3xl break-all md:break-normal"
                    truncate={false}
                    maxVisibleValues={5}
                    ignoreNotFound={usersAreLoading}
                    nullable
                    multiple
                    multiline
                    {...getTestProps(testId, 'users', 'testId')}
                  />
                )}

                {mode !== 'edit' && (
                  <div className="flex flex-col w-full justify-center max-w-screen-2xl">
                    <Heading
                      level={4}
                      additionalClasses="font-normal dark:text-white"
                    >
                      <div className="flex flex-col sm:flex-row items-center justify-between">
                        <span
                          className="text-xl text-md-2xl"
                          {...getTestProps(testId, 'text-choose-type-space')}
                        >
                          {' '}
                          {t('Spaces.ChooseTheSpaceType')}
                        </span>
                        <div className="flex flex-row">
                          <span
                            className="font-medium text-base text-md-lg mr-2"
                            {...getTestProps(testId, 'switch-label-monthly')}
                          >
                            {t('Spaces.PayMonthly')}
                          </span>
                          <Switch
                            name="payment-period"
                            checked={isPaymentYearly}
                            onChange={() =>
                              setIsPaymentYearly(!isPaymentYearly)
                            }
                            {...getTestProps(testId, 'switch-period', 'testId')}
                          />
                          <span
                            className="font-medium text-base text-md-lg ml-2"
                            {...getTestProps(testId, 'switch-label-yearly')}
                          >
                            {t('Spaces.PayAnnually')}
                          </span>
                        </div>
                      </div>
                    </Heading>
                    <div
                      className={twMerge(
                        'grid grid-cols-[repeat(auto-fit,_minmax(14rem,_1fr))] gap-10 mt-2 mb-6',
                        'rounded border border-transparent',
                      )}
                    >
                      {allPlans?.map((plan) => (
                        <SpaceTypeCard
                          key={plan.id}
                          name={plan.visibleName}
                          color={getColorByPlanName(
                            plan.visibleName || plan.name,
                          )}
                          price={getPriceOrContactUs(
                            isPaymentYearly ? plan.priceYearly : plan.price,
                            t('AccountSettings.ContactUs'),
                            'month',
                          )}
                          limit={
                            plan.visibleName === 'Free'
                              ? `${spacesFreeCount?.data}/${freeSpaceLimit}`
                              : ''
                          }
                          plan={plan}
                          selected={formik.values.planId === plan.id}
                          disabled={
                            mode === 'add' &&
                            plan.visibleName === 'Free' &&
                            spacesFreeCount?.data === freeSpaceLimit
                          }
                          handleSelected={(plan) =>
                            handleSelected(formik, plan)
                          }
                          {...getTestProps(
                            testId,
                            `type-card-${plan.id}`,
                            'testId',
                          )}
                        />
                      ))}
                    </div>

                    <HelpErrorTextsTemplate
                      error={
                        formik.status?.errors?.planId || formik.errors.planId
                      }
                      {...getTestProps(testId, 'space-type-error', 'testId')}
                    />

                    <SaveButton />
                  </div>
                )}
              </form>
            </div>
          )}
        </div>

        {isFormVisible && mode === 'edit' && (
          <div
            className={twMerge(
              'px-4 xl:pl-3.5 xl:pr-7 pb-7',
              'border-t md:border-t-0 md:border-l dark:border-slate-800',
              'flex flex-col gap-5 w-full',
            )}
          >
            <Panel
              title={
                <>
                  <Heading level={4} additionalClasses="dark:text-white">
                    {t('Spaces.SpaceLimits')}
                  </Heading>

                  <Button
                    buttonSize="xs"
                    onClick={handleUpgrade}
                    {...getTestProps(testId, 'upgrade-space', 'testId')}
                  >
                    {t('Spaces.UpgradeSpace')}
                  </Button>
                </>
              }
              additionalTitleWrapClasses="w-full"
              additionalTitleClasses="flex justify-between items-center w-full"
              isCollapsable={false}
              open
            >
              <SpaceLimits
                key={spaceId + spaceData?.users?.length}
                id={spaceId}
                planLimits={spaceData?.planLimits}
                isAdmin={isAdmin}
                isBuyExtraVisible={false}
                highlightExceededLimits
              />
            </Panel>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpaceModify;

SpaceModify.propTypes = {
  /**
   * Test id for add space page
   */
  testId: PropTypes.string,
  /**
   * Allow to use additional options. "upgrade" | "edit" | "add"
   */
  mode: PropTypes.string,
  /**
   *  Delay timeout for redirect to page after succes change subskription
   */
  delaySub: PropTypes.number,
};

SpaceModify.defaultProps = {
  testId: '',
  mode: 'add',
  delaySub: 5000,
};
