import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';
import moment from 'moment';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Hooks
import {
  useContentObject,
  useContentType,
  useContentObjectVersions,
  useWorkflow,
  useSearch,
  usePlugins,
  usePluginsSettings,
} from '../../hooks/api';
import useToken from '../../hooks/useToken';
import useOnce from '../../hooks/useOnce';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import { useContentTypesByName } from '../../hooks/api/useContentTypesByName';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import ContentObjectVersions from '../../components/ContentObjectVersions/ContentObjectVersions';
import ContentObjectWebhooks from '../../components/ContentObjectWebhooks/ContentObjectWebhooks';
import InformationBlock from '../../components/InformationBlock/InformationBlock';
import ContentObjectPlugins from '../../components/ContentObjectPlugins/ContentObjectPlugins';
import Button from '../../components/Button/Button';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';

// :: Components Inner
import StatusPanel from './StatusPanel/StatusPanel';
import ContentObjectBacklinks from './ContentObjectBacklinks/ContentObjectBacklinks';

// :: Form
import ContentObjectForm from '../../form/ContentObjectForm/ContentObjectForm';

// :: Helpers
import {
  getSortedBy,
  getUpdateInformationBlockStatus,
  getTestProps,
  getPluginsIframeOptions,
} from '../../lib/helpers';

// :: Lib Api
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import {
  getContentObjectVersion,
  listWebhooks,
  putWorkflow,
  postContentObjectVersions,
} from '../../lib/flotiq-client';
import { saveNewCTO, updateCTO } from '../../lib/flotiq-client/api-helpers';
import { FormAddSidebarPanelEvent } from '../../lib/flotiq-plugins/plugin-events/FormAddSidebarPanelEvent';
import { FormRenderSidebarPluginsEvent } from '../../lib/flotiq-plugins/plugin-events/FormRenderSidebarPluginsEvent';

// :: Icons
import {
  HouseIcon,
  WarningIcon,
  ArrowCollapseLeftIcon,
  ArrowCollapseRightIcon,
} from '../../images/shapes';

// :: Hooks
import useFirstLoading from '../../hooks/useFirstLoading';
import useSpace from '../../hooks/useSpace';
import usePluginResults from '../../hooks/usePluginResults';

const versionsHookParams = {
  limit: 10000,
  page: 1,
};

const USER_PLUGINS_PARAMS = {
  limit: 10000,
  page: 1,
};

/**
 * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
 */
const AddContentObject = ({ duplicate, testId }) => {
  const { t } = useTranslation();
  const navigateOnSave = useRef();
  const modal = useModals();
  const jwt = useToken();
  const { space, buildUrlWithSpace } = useSpace();
  const { updateAppContext } = useContext(AppContext);
  const navigate = useNavigate();
  const { permissions } = useContext(UserContext);
  const { setDirty } = useContext(DirtyHandlerContext);

  const [user, setUser] = useLocalStorageState('cms.user');

  const { data: userPlugins } = usePluginsSettings(USER_PLUGINS_PARAMS);

  const pluginsOptions = useMemo(
    () => ({
      pause: !permissions.canCo('_plugin'),
    }),
    [permissions],
  );
  const { data: plugins } = usePlugins(null, pluginsOptions);

  const { contentTypeName, id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [contentObjectData, setContentObjectData] = useState({});
  const [isCtoLoading, setIsCtoLoading] = useState(false);
  const [postVersionUpdated, setPostVersionUpdated] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  const ctdPlugins = useMemo(
    () => getPluginsIframeOptions(plugins, contentTypeName),
    [plugins, contentTypeName],
  );

  const [backlinksPage, setBacklinksPage] = useState(1);
  const backlinkSearchParams = useMemo(
    () => [
      {
        q: `"/api/v1/content/${contentTypeName}/${id}"`,
        limit: 5,
        page: backlinksPage,
      },
      {
        pause: duplicate || !id,
      },
    ],
    [contentTypeName, duplicate, id, backlinksPage],
  );

  const [backlinks, backlinkPagination] = useSearch(...backlinkSearchParams);

  const ctdNames = useMemo(
    () => backlinks?.map(({ item }) => item?.internal?.contentType),
    [backlinks],
  );
  const [backlinkContentTypes] = useContentTypesByName(ctdNames);

  const [workflowStatusLoading, setWorkflowStatusLoading] = useState(false);
  const [workflowHasSelfTransition, setWorkflowHasSelfTransition] =
    useState(true);

  const {
    data: ctoVersions,
    errors: contentVersionsErrors,
    isLoading: ctoVersionLoading,
    reload: reloadContentVersions,
  } = useContentObjectVersions(contentTypeName, id, versionsHookParams);

  const [customWebhooks, setCustomWebhooks] = useState([]);

  const {
    entity: contentObject,
    isLoading: contentObjectIsLoading,
    updateEntity: updateContentObject,
    deleteEntity: deleteContentObject,
    errors: contentObjectErrors,
    reload: contentObjectReload,
  } = useContentObject(contentTypeName, id);

  const fetchWebhooks = useCallback(async () => {
    if (!permissions.canCo('_webhooks')) return;
    try {
      // List webhooks with content type definitions based on content type name
      const { body, status } = await listWebhooks(jwt, space, {
        filters: JSON.stringify({
          'actions[*].action': { type: 'contains', filter: '"Custom"' },
          'content_type_definitions[*].content_type_definition_name': {
            type: 'contains',
            filter: `"${contentTypeName}"`,
          },
          enabled: { type: 'equals', filter: true },
        }),
      });

      // List webhooks with content type definitions that is for All (default)
      const { body: bodyDefault, status: statusDefault } = await listWebhooks(
        jwt,
        space,
        {
          filters: JSON.stringify({
            'actions[*].action': { type: 'contains', filter: '"Custom"' },
            content_type_definitions: {
              type: 'contains',
              filter: '[]',
            },
            enabled: { type: 'equals', filter: true },
          }),
        },
      );

      const webhooksList = [...body.data, ...bodyDefault.data];
      const mapFromWebhooks = new Map(webhooksList.map((c) => [c.id, c]));
      const uniqueList = [...mapFromWebhooks.values()];

      checkResponseStatus(body, status);
      checkResponseStatus(bodyDefault, statusDefault);

      setCustomWebhooks(uniqueList);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('ContentForm.CouldntFetch'),
        );
      }
    }
  }, [permissions, jwt, space, contentTypeName, t]);

  useOnce(fetchWebhooks);
  useEffect(() => {
    setContentObjectData(contentObject);
  }, [contentObject]);

  const handleDeleteObject = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteContentObject({ contentTypeName });
      checkResponseStatus(body, status);
      toast.success(t('ContentForm.Deleted'));

      setDirty(false);
      navigate(buildUrlWithSpace(`content-type-objects/${contentTypeName}`));
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('ContentForm.CouldntDelete'),
        );
      }
    }
  }, [
    modal,
    deleteContentObject,
    contentTypeName,
    t,
    navigate,
    buildUrlWithSpace,
    setDirty,
  ]);

  const deleteObject = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('ContentForm.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteObject(),
    );
    setIsDeleting(false);
  }, [handleDeleteObject, modal, t]);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    status: contentTypeStatus,
    isLoading: contentTypeLoading,
  } = useContentType(contentTypeName);

  const firstLoading = useFirstLoading(
    (!contentTypeLoading || !contentTypeName) &&
      (!contentObjectIsLoading || !id) &&
      !isCtoLoading,
    contentTypeName + id + isCtoLoading,
  );

  const {
    entity: workflowConfig,
    errors: workflowErrors,
    isLoading: workflowConfigLoading,
    reload: workflowConfigReload,
  } = useWorkflow(contentTypeName, id, contentObject?.internal?.latestVersion);

  useEffect(() => {
    const selfTransition = `_${contentObject?.internal?.workflowState}_${contentObject?.internal?.workflowState}`;
    const hasSelfTransition = workflowConfig?.enabled_transitions?.some(
      (transition) => transition.name === selfTransition,
    );
    setWorkflowHasSelfTransition(hasSelfTransition);
  }, [workflowConfig, contentObject]);

  useApiErrorsToast(workflowErrors);
  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(contentVersionsErrors);
  useApiErrorsToast(contentObjectErrors);

  const { canCreate, canDelete, canUpdate, canRead } = useMemo(
    () => permissions.getCoPermissions(contentTypeName) || {},
    [contentTypeName, permissions],
  );

  const notAllowed = useMemo(
    () => contentType?.internal || !canRead,
    [contentType, canRead],
  );

  const label = useMemo(() => contentType?.label || '', [contentType]);

  const actionMenuItems = useMemo(() => {
    const items = [
      {
        key: 'leave',
        label: (
          <div className="whitespace-nowrap">{t('Global.SaveAndLeave')}</div>
        ),
        type: 'submit',
        form: 'cto-form',
        onClick: () => {
          navigateOnSave.current = true;
        },
      },
    ];
    if (id && !duplicate && canCreate)
      items.push({
        key: 'duplicate',
        label: <div className="whitespace-nowrap">{t('Global.Duplicate')}</div>,
        link: buildUrlWithSpace(
          `content-type-objects/duplicate/${contentTypeName}/${id}`,
        ),
      });
    return items;
  }, [t, id, duplicate, canCreate, buildUrlWithSpace, contentTypeName]);

  const pageTitle = useMemo(() => {
    if (duplicate) return 'Duplicate';
    else if (id) return 'Edit';
    return 'Add';
  }, [id, duplicate]);

  const topBarButtons = useMemo(() => {
    const buttons = [
      {
        key: 'cancel',
        label: t('Global.Cancel'),
        link: buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
        color: 'gray',
        disabled: isSaving || isDeleting,
      },
    ];

    if ((canUpdate && id) || (canCreate && !id)) {
      buttons.push({
        key: 'save',
        label: t('Global.Save'),
        onClick: () => {
          navigateOnSave.current = false;
        },
        iconImage: isSaving ? <Loader size="small" type="spinner-grid" /> : '',
        disabled: isSaving || isDeleting,
        menuItems: actionMenuItems,
        type: 'submit',
        form: 'cto-form',
        ...getTestProps(testId, 'action-menu', 'testId'),
      });
    }

    if (id && !duplicate && canDelete)
      buttons.unshift({
        key: 'delete',
        label: t('Global.Delete'),
        onClick: deleteObject,
        color: 'redBordered',
        iconImage: isDeleting ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          ''
        ),
        disabled: isSaving || isDeleting,
      });
    return buttons;
  }, [
    t,
    contentTypeName,
    buildUrlWithSpace,
    isSaving,
    isDeleting,
    canUpdate,
    id,
    canCreate,
    duplicate,
    canDelete,
    deleteObject,
    actionMenuItems,
    testId,
  ]);

  const handlePostVersion = useCallback(async () => {
    try {
      const { body, status } = await postContentObjectVersions(jwt, space, {
        contentTypeName: contentTypeName,
        id: id,
      });
      checkResponseStatus(body, status);
      toast.success(t(`ObjectStatus.SuccessWorkflowVersionUpdated`));
      setPostVersionUpdated(true);
    } catch (error) {
      toast.error(error.message);
    }
  }, [jwt, space, contentTypeName, id, t]);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: `content/${label || contentTypeName}`,
      menuItemOpen: 'content',
      topBar: {
        heading: t(`ContentForm.${pageTitle}`, { contentTypeName: label }),
        buttons: topBarButtons,
      },
    }));
  }, [updateAppContext, topBarButtons, label, contentTypeName, pageTitle, t]);

  useEffect(() => {
    workflowConfigReload();
  }, [contentObject, workflowConfigReload]);

  const saveNewObject = useCallback(
    async (values) => {
      setIsSaving(true);
      const [formikValues, hasErrors] = await saveNewCTO(
        jwt,
        space,
        values,
        contentTypeName,
        user,
        setUser,
        t,
      );
      setIsSaving(false);
      if (!hasErrors)
        navigate(
          buildUrlWithSpace(
            `content-type-objects/edit/${contentTypeName}/${formikValues[0].id}`,
          ),
        );
      return formikValues;
    },
    [
      jwt,
      space,
      contentTypeName,
      user,
      setUser,
      t,
      navigate,
      buildUrlWithSpace,
    ],
  );

  const updateObject = useCallback(
    async (values) => {
      setIsSaving(true);

      if (contentType?.workflowId !== 'generic' && !workflowHasSelfTransition) {
        await handlePostVersion();
      }

      const [formikValues] = await updateCTO(
        values,
        contentTypeName,
        updateContentObject,
        t,
      );

      await reloadContentVersions();
      setIsSaving(false);
      return formikValues;
    },
    [
      updateContentObject,
      contentType,
      contentTypeName,
      handlePostVersion,
      t,
      reloadContentVersions,
      workflowHasSelfTransition,
    ],
  );

  const handleVersionSelectCallback = useCallback(
    async (versionNumber) => {
      setIsCtoLoading(true);
      try {
        const { body, status } = await getContentObjectVersion(jwt, space, {
          contentTypeName: contentTypeName,
          id: id,
          versionNumber: versionNumber,
        });
        checkResponseStatus(body, status);

        setContentObjectData(body);
        setPostVersionUpdated(false);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(t('ContentForm.CouldntRevertVersion'));
        }
      }
      setIsCtoLoading(false);
    },
    [contentTypeName, id, jwt, t, space],
  );

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('ObjectsOfType.Title', { contentTypeName: label }),
          link: buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Title',
        },
        {
          label: t(`ContentForm.${pageTitle}`, { contentTypeName: label }),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'Add',
        },
      ],
    }));
  }, [
    topBarButtons,
    label,
    contentTypeName,
    pageTitle,
    t,
    updateAppContext,
    buildUrlWithSpace,
  ]);

  const emptyCTD = useMemo(() => {
    if (firstLoading) {
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loader` : ''}
        />
      );
    }
    if (notAllowed) {
      toast.error(t('ContentForm.CouldntFind', { contentTypeName: label }));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('ContentForm.CouldntFind')}
          />
          {contentTypeStatus === 404 || notAllowed
            ? t('ContentForm.CouldntFind', { contentTypeName })
            : t('ContentForm.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [
    firstLoading,
    notAllowed,
    testId,
    t,
    contentTypeStatus,
    contentTypeName,
    label,
  ]);

  const showForm = useMemo(
    () => !notAllowed && !firstLoading && contentType,
    [notAllowed, firstLoading, contentType],
  );

  const handleChangeObjectStatus = useCallback(
    async (objectStatus) => {
      if (contentObject.internal.workflowState !== objectStatus) {
        setWorkflowStatusLoading(true);
        setIsSaving(true);
        try {
          const { body, status } = await putWorkflow(jwt, space, {
            objectType: contentTypeName,
            objectId: id,
            revision: contentObject.internal.latestVersion,
            action: `_${contentObject.internal.workflowState}_${objectStatus}`,
          });
          checkResponseStatus(body, status);
          toast.success(t('ObjectStatus.SuccessWorkflowStatusUpdate'));
          contentObjectReload();
          workflowConfigReload();
        } catch (error) {
          toast.error(error.message);
        }
        await reloadContentVersions();
        setWorkflowStatusLoading(false);
        setIsSaving(false);
      }
    },
    [
      space,
      t,
      id,
      jwt,
      reloadContentVersions,
      contentObject,
      contentObjectReload,
      contentTypeName,
      workflowConfigReload,
    ],
  );

  const handleUnpublish = useCallback(async () => {
    setWorkflowStatusLoading(true);
    try {
      const { body, status } = await putWorkflow(jwt, space, {
        objectType: contentTypeName,
        objectId: id,
        revision: contentObject.internal.workflowPublicVersion,
        action: `_public_archive`,
      });
      checkResponseStatus(body, status);
      toast.success(t('ObjectStatus.SuccessWorkflowUnpublish'));
      contentObjectReload();
      workflowConfigReload();
    } catch (error) {
      toast.error(error.message);
    }
    await reloadContentVersions();
    setWorkflowStatusLoading(false);
  }, [
    space,
    t,
    id,
    jwt,
    reloadContentVersions,
    contentObject,
    contentObjectReload,
    contentTypeName,
    workflowConfigReload,
  ]);

  const getInformationBlockStatus = useCallback(
    (workflowPublicVersion, workflowState) => {
      const publicVersionExist =
        workflowPublicVersion > -1 ? '_withPublicVersion' : '_noPublicVersion';

      switch (`${publicVersionExist}_${workflowState}`) {
        case '_withPublicVersion_draft':
          return 'published';
        case '_withPublicVersion_review':
          return 'published';
        case '_withPublicVersion_public':
          return 'public';
        case '_withPublicVersion_archive':
          return 'archive';

        case '_noPublicVersion_draft':
          return 'draft';
        case '_noPublicVersion_review':
          return 'hidden';
        case '_noPublicVersion_public':
          return 'public';
        case '_noPublicVersion_archive':
          return 'archive';

        default:
          return 'hidden';
      }
    },
    [],
  );

  const showInformationBlock = useMemo(
    () =>
      contentType?.workflowId !== 'generic' &&
      contentObjectData?.internal &&
      !duplicate,
    [contentType, contentObjectData, duplicate],
  );

  const showStatusPanel = useMemo(
    () =>
      contentType?.workflowId !== 'generic' &&
      contentObjectData?.internal &&
      !duplicate,
    [contentType, contentObjectData, duplicate],
  );

  const handleShowSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);

  const isFormDisabled = useMemo(() => {
    if (isSaving || isDeleting) return true;
    if (id) return !canUpdate;
    return !canCreate;
  }, [canCreate, canUpdate, id, isDeleting, isSaving]);

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
   */
  const pluginSidebarPanels = usePluginResults(
    'flotiq.form.sidebar-panel::add',
    FormAddSidebarPanelEvent,
    {
      contentType,
      contentObject: contentObjectData,
      disabled: isFormDisabled,
      duplicate,
      create: !id,
      userPlugins,
    },
  );

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-plugins::render"
   */
  const pluginReplacementPanels = usePluginResults(
    'flotiq.form.sidebar-plugins::render',
    FormRenderSidebarPluginsEvent,
    {
      plugins,
      contentObject,
      contentType,
      duplicate,
      create: !id,
      disabled: isFormDisabled,
    },
  );

  const validPluginReplacement = useCallback(
    (pluginReplacementPanels, ctdPlugins) => {
      let ctdCurrentPlugins;

      if (pluginReplacementPanels[0]?.results) {
        if (pluginReplacementPanels[0].other) {
          ctdCurrentPlugins = getPluginsIframeOptions(
            pluginReplacementPanels[0].other,
            contentTypeName,
          );
        }
      } else if (pluginReplacementPanels.length > 0) {
        ctdCurrentPlugins = [];
      } else {
        ctdCurrentPlugins = ctdPlugins;
      }

      return (
        <>
          <ElementFromPlugin
            results={
              pluginReplacementPanels[0]?.results || pluginReplacementPanels
            }
            order={pluginReplacementPanels[0]?.order || 60}
          />
          {ctdCurrentPlugins.length > 0 && (
            <ContentObjectPlugins
              key={contentObjectData?.internal?.updatedAt}
              plugins={ctdCurrentPlugins}
              contentObject={contentObjectData}
              ctdName={contentTypeName}
              additionalClasses={twMerge(
                `order-60`,
                !showSidebar && 'lg:hidden',
              )}
              {...getTestProps(testId, 'plugins', 'testId')}
            />
          )}
        </>
      );
    },
    [contentObjectData, contentTypeName, showSidebar, testId],
  );

  return (
    <div className="flex items-stretch w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>
          {t(`ContentForm.${pageTitle}`, { contentTypeName: label })}
        </title>
      </Helmet>
      <div className="flex flex-col w-full">
        {showForm ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 h-full relative mt-7">
            <div
              className={twMerge(
                'relative bg-white dark:bg-slate-950 rounded-lg mx-4 xl:ml-7 xl:mr-3.5 mb-7',
                showSidebar
                  ? 'lg:col-span-2 xl:col-span-3'
                  : 'lg:col-span-3 xl:col-span-4 lg:!mr-[80px] xl:!mr-[90px]',
              )}
            >
              <div className="space-y-3 md:space-y-6 p-7 md:py-10 md:px-14">
                <ContentObjectForm
                  key={pageTitle}
                  contentType={contentType}
                  contentObject={contentObjectData || {}}
                  isEditing={!!(id && !duplicate)}
                  onSubmit={id && !duplicate ? updateObject : saveNewObject}
                  disabled={isFormDisabled}
                  navigateOnSave={navigateOnSave}
                  hasInitialData={!!id}
                  isFullSize={!showSidebar}
                  userPlugins={userPlugins}
                  {...getTestProps(testId, 'form', 'testId')}
                />
              </div>
              <Button
                onClick={handleShowSidebar}
                iconImage={
                  showSidebar ? (
                    <ArrowCollapseRightIcon className="w-5 h-5 text-blue" />
                  ) : (
                    <ArrowCollapseLeftIcon className="w-5 h-5 text-blue" />
                  )
                }
                additionalIconClasses={'min-w-10 w-10'}
                buttonColor="borderless"
                additionalClasses={twMerge(
                  'hidden absolute top-2.5 right-1.5',
                  process.env.REACT_APP_ENABLE_CTO_FORM_RESIZE.split(',').join(
                    ',',
                  ) === 'true' && 'lg:flex',
                )}
                noPaddings
                {...getTestProps(testId, 'toggle-sidebar', 'testId')}
              />
            </div>
            <div
              className={twMerge(
                'px-4 xl:pl-3.5 xl:pr-7 pb-7 border-t md:border-t-0 md:border-l dark:border-slate-800',
                'flex flex-col gap-5 w-full',
                !showSidebar &&
                  'lg:border-none lg:absolute lg:right-0 lg:w-16 lg:p-0 lg:h-full',
              )}
              {...getTestProps(testId, 'sidebar-container')}
            >
              {showInformationBlock && (
                <InformationBlock
                  status={getUpdateInformationBlockStatus(
                    getInformationBlockStatus(
                      contentObjectData?.internal?.workflowPublicVersion,
                      contentObjectData?.internal?.workflowState,
                    ),
                    getSortedBy(ctoVersions, 'version')?.[0]?.version,
                    postVersionUpdated,
                    contentObjectData?.internal?.latestVersion,
                  )}
                  versionDate={moment(
                    contentObjectData?.internal?.workflowPublishedAt ||
                      contentObjectData?.internal?.updatedAt,
                  ).format('L LTS')}
                  onClick={handleUnpublish}
                  config={{
                    published: {
                      button: true,
                      information: true,
                      title: false,
                      description: false,
                      hidden: false,
                    },
                    public: {
                      button: true,
                      information: false,
                      title: false,
                      description: false,
                      hidden: false,
                    },
                    draft: {
                      button: false,
                      information: false,
                      title: false,
                      description: false,
                      hidden: false,
                    },
                    archive: {
                      button: false,
                      information: false,
                      title: false,
                      description: false,
                      hidden: false,
                    },
                    hidden: {
                      hidden: true,
                    },
                  }}
                  additionalTitleClasses={'text-lg order-0'}
                  additionalClasses={twMerge(!showSidebar && 'lg:hidden')}
                  {...getTestProps(testId, 'information-block', 'testId')}
                />
              )}

              {showStatusPanel && (
                <StatusPanel
                  onChange={handleChangeObjectStatus}
                  status={contentObjectData?.internal?.workflowState}
                  loading={workflowStatusLoading || workflowConfigLoading}
                  workflowConfig={workflowConfig}
                  additionalTitleClasses={'!text-lg order-10'}
                  additionalClasses={twMerge(!showSidebar && 'lg:hidden')}
                  {...getTestProps(testId, 'status-panel', 'testId')}
                />
              )}

              {!duplicate && (
                <ContentObjectInformations
                  createdAt={contentObjectData?.internal?.createdAt}
                  updatedAt={contentObjectData?.internal?.updatedAt}
                  updatedAtText={
                    contentType?.workflowId === 'publishing' &&
                    t('TypeDefinitionCard.LastUpdated')
                  }
                  createdBy={contentObjectData?.internal?.createdBy}
                  lastPublishedAt={
                    contentObjectData?.internal?.workflowPublishedAt
                  }
                  additionalClasses={twMerge('order-20')}
                  isPinned={!showSidebar}
                  testId={testId}
                />
              )}

              {customWebhooks.length > 0 && !duplicate && id && (
                <ContentObjectWebhooks
                  webhooks={customWebhooks}
                  contentObjectId={id}
                  contentTypeDefinitionName={contentTypeName}
                  additionalClasses={twMerge(
                    'order-30',
                    !showSidebar && 'lg:hidden',
                  )}
                  testId={testId}
                />
              )}

              {ctoVersions?.length > 0 && !duplicate && (
                <ContentObjectVersions
                  loading={ctoVersionLoading}
                  versions={getSortedBy(ctoVersions, 'version')}
                  testId={testId}
                  selectVersionCallback={handleVersionSelectCallback}
                  additionalClasses={twMerge(
                    'order-40',
                    !showSidebar && 'lg:hidden',
                  )}
                />
              )}

              {backlinks?.length > 0 && (
                <ContentObjectBacklinks
                  backlinks={backlinks}
                  contentTypes={backlinkContentTypes}
                  pagination={backlinkPagination}
                  onPageChange={setBacklinksPage}
                  additionalClasses={twMerge(
                    'order-50',
                    !showSidebar && 'lg:hidden',
                  )}
                />
              )}

              {!duplicate &&
                ctdPlugins &&
                ctdPlugins.length > 0 &&
                validPluginReplacement(pluginReplacementPanels, ctdPlugins)}

              {pluginSidebarPanels?.length > 0 && (
                <ElementFromPlugin results={pluginSidebarPanels} />
              )}
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center h-full
          bg-white dark:bg-slate-950 rounded-lg m-4 xl:m-12"
          >
            {emptyCTD}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddContentObject;

AddContentObject.propTypes = {
  /**
   * If object is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

AddContentObject.defaultProps = {
  duplicate: false,
  testId: '',
};
