import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import { useModals } from '../../contexts/ModalContext';
import SidebarContext from '../../contexts/SidebarContext';
import UserContext from '../../contexts/UserContext';

// :: Images
import {
  HouseIcon,
  PlusSolidIcon,
  WarningIcon,
  ZoomMaleWorkingImage,
} from '../../images/shapes';
import imageFolder from '../../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-5-placeholder.jpg';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import {
  useConstraints,
  useContentTypes,
  useDefinitionsCount,
} from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import useSpace from '../../hooks/useSpace';
import { useFeaturedImages } from '../../hooks/api/useFeaturedImages';

// :: Lib
import { getFoldersStructure, getTestProps } from '../../lib/helpers';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { deleteContentType } from '../../lib/flotiq-client';
import { RolePermissions } from '../../lib/rolePermissions';

// :: Components
import Dropdown from '../../components/Dropdown/Dropdown';
import Heading from '../../components/Heading/Heading';
import Input from '../../components/Input/Input';
import LinkButton from '../../components/LinkButton/LinkButton';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Tooltip from '../../components/Tooltip/Tooltip';
import TypeDefinitionCard from '../../components/TypeDefinitionCard/TypeDefinitionCard';

// :: Defined
import { DEFINED_TYPES } from './definedTypes';

const ContentTypeDefinitions = ({ limit, pagination, testId }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { space } = useSpace();
  const { updateAppContext } = useContext(AppContext);
  const { buildUrlWithSpace } = useSpace();
  const { reloadCtd: reloadSideBarCtd } = useContext(SidebarContext);
  const modal = useModals();
  const { permissions, planLimits, isAdmin } = useContext(UserContext);

  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [queryBy, setQueryBy] = useState('label');
  const [currentCtdData, setCurrentCtdData] = useState([]);
  const [currentFolderName, setCurrentFolderName] = useState();
  const [searchError, setSearchError] = useState();

  const { entity: ctdCount, reload: reloadCtdCount } =
    useConstraints('ctd-count');
  const { entity: ctoCount } = useConstraints('cto-count');

  const params = useMemo(
    () => ({
      limit: limit,
      internal: 0,
      [queryBy]: query,
      page,
    }),
    [query, queryBy, page, limit],
  );

  const {
    data: ctd,
    isLoading: ctdAreLoading,
    errors: ctdErrors,
    pagination: ctdPagination,
    reload: reloadCtd,
  } = useContentTypes(params);

  const [featuredImages, featuredImagesLoading] = useFeaturedImages(ctd);

  const handleCurrentCtd = useCallback(() => {
    setCurrentCtdData([]);

    if (!ctdAreLoading) {
      const filteredCtd = ctd.filter((ctdData) =>
        permissions.canCtd(ctdData.name),
      );

      setCurrentCtdData(
        getFoldersStructure(filteredCtd, currentFolderName, !query),
      );
    }
  }, [ctd, ctdAreLoading, currentFolderName, permissions, query]);

  const handleCurrentCtdDebounced = useDebounceCallback(handleCurrentCtd, 0);

  useEffect(() => {
    handleCurrentCtdDebounced();
  }, [handleCurrentCtdDebounced]);

  useEffect(() => {
    if (!firstLoading) return;
    if (!ctdAreLoading) {
      setFirstLoading(false);
    }
    if (ctd.length > 0) setInitDataHasContent(true);
  }, [ctd.length, ctdAreLoading, firstLoading]);

  const ctdWithCountsParams = useMemo(
    () => ({
      content_type: ctd?.map((element) => element.name),
    }),
    [ctd],
  );

  const ctdWithCountsOptions = useMemo(
    () => ({
      pause: ctdAreLoading,
    }),
    [ctdAreLoading],
  );

  const {
    entity: ctdWithCounts,
    isLoading: ctdWithCountsAreLoading,
    errors: ctdWithCountErrors,
  } = useDefinitionsCount(ctdWithCountsParams, ctdWithCountsOptions);

  useApiErrorsToast(ctdErrors);
  useApiErrorsToast(ctdWithCountErrors);

  useEffect(() => {
    const res = [
      {
        label: <HouseIcon className="w-3 text-blue" />,
        link: buildUrlWithSpace(''),
        additionalClasses: 'text-slate-400 truncate text-center',
        key: 'Dashboard',
      },
      {
        label: t('Global.DefinitionBuilder'),
        additionalClasses: 'text-zinc-600 truncate',
        disabled: !currentFolderName,
        key: 'DefinitionBuilder',
        onClick: currentFolderName
          ? () => setCurrentFolderName(undefined)
          : null,
      },
    ];

    if (currentFolderName) {
      res.push({
        label: `Folder: ${currentFolderName}`,
        additionalClasses: 'text-zinc-600 truncate',
        disabled: true,
        key: 'DefinitionBuilderFolder',
      });
    }

    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: res,
    }));
  }, [
    t,
    updateAppContext,
    buildUrlWithSpace,
    currentFolderName,
    setCurrentFolderName,
  ]);

  const handleEmptyResult = useMemo(() => {
    if (ctd?.length > 0 && !ctdAreLoading && !ctdWithCountsAreLoading)
      return null;
    if (ctdAreLoading || ctdWithCountsAreLoading) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    if (ctdWithCountErrors || ctdErrors || query) {
      return (
        <Heading level={2} additionalClasses="dark:text-white">
          <div
            className={'inline-flex items-center'}
            {...getTestProps(testId, 'no-data')}
          >
            <WarningIcon className="text-red h-10 mr-3" />
            {query
              ? t('ContentDefinition.FiltersEmptyResult')
              : t('Media.OnErrorMessage')}
          </div>
        </Heading>
      );
    }
    return (
      <div className="flex flex-col gap-4 md:gap-8 lg:gap-14 w-full max-w-[87rem]">
        <div className="flex flex-row md:gap-4 lg:gap-10 xl:gap-20">
          <ZoomMaleWorkingImage className="hidden md:flex h-28 lg:h-48" />
          <div className="flex flex-col justify-between text-start">
            <div
              className="flex flex-col justify-between text-start font-bold text-2xl lg:text-4xl xl:text-5xl
            xl:leading-[52px]"
            >
              <p className="text-blue-600 dark:text-white">
                {t('ContentDefinition.CreateFirst')}
              </p>
              <p className="text-indigo-950 dark:text-gray-300">
                {t('ContentDefinition.CreateFirstDefinition')}
              </p>
            </div>
            <p className="text-sm md:text-base lg:text-xl font-normal max-w-xl dark:text-white">
              {t('ContentDefinition.CreateFirstDescription')}
            </p>
          </div>
        </div>
        <div className="grid gap-2 sm:gap-6 grid-cols-2 xl:grid-cols-3">
          {Object.values(DEFINED_TYPES).map((type) => (
            <Link
              key={type.name}
              className={twMerge(
                'w-full h-32 bg-slate-50 rounded-lg hover:bg-slate-100',
                'text-indigo-950 text-base md:text-xl font-bold dark:bg-slate-800',
              )}
              to={buildUrlWithSpace(
                `content-type-definitions/add?defaultType=${type.name}`,
              )}
              {...getTestProps(testId, `add-${type.name}`)}
            >
              <span className="h-full flex flex-col items-center justify-center gap-2 dark:text-white">
                <PlusSolidIcon className="h-6 text-blue" />
                {type.label}
              </span>
            </Link>
          ))}

          <Link
            className={twMerge(
              'w-full h-32 bg-slate-50 rounded-lg hover:bg-slate-100',
              'text-indigo-950 text-base md:text-xl font-bold dark:bg-slate-800',
            )}
            to={buildUrlWithSpace('content-type-definitions/add')}
            {...getTestProps(testId, 'add-custom')}
          >
            <span className="h-full flex flex-col items-center justify-center gap-2 dark:text-white">
              <PlusSolidIcon className="h-6 text-blue" />
              {t('ContentDefinition.Custom')}
            </span>
          </Link>
        </div>
      </div>
    );
  }, [
    ctd,
    ctdWithCountErrors,
    ctdErrors,
    ctdAreLoading,
    ctdWithCountsAreLoading,
    query,
    testId,
    buildUrlWithSpace,
    t,
  ]);

  const resources = useMemo(
    () => [
      {
        key: 'ctd',
        label: t('ContentDefinition.ContentType'),
        value: ctdCount?.data,
        barColor: 'blue',
        limit: planLimits?.ctd_limit,
      },
      {
        key: 'cto',
        label: t('ContentDefinition.ContentObject'),
        value: ctoCount?.data,
        barColor: 'orange',
        limit: planLimits?.cto_limit,
      },
    ],
    [t, ctdCount, ctoCount, planLimits],
  );

  const ctdLimitExceeded = useMemo(() => {
    return resources[0].limit !== -1
      ? resources[0].value >= resources[0].limit
      : false;
  }, [resources]);

  const addCtdButtonLabel = useMemo(() => {
    if (ctdLimitExceeded) {
      return (
        <Tooltip
          tooltip={t('ContentDefinition.PlanExceeded')}
          tooltipPlacement="bottomCenter"
          phoneTooltipPlacement="topCenter"
        >
          {t('ContentDefinition.Add')}
        </Tooltip>
      );
    }
    return t('ContentDefinition.Add');
  }, [ctdLimitExceeded, t]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'definitionBuilder',
      topBar: {
        heading: t('Global.DefinitionBuilder'),
        buttons: permissions.canCtd(
          '*',
          RolePermissions.PERMISSIONS_TYPES.CREATE,
        )
          ? [
              {
                key: 'add',
                label: addCtdButtonLabel,
                link: buildUrlWithSpace('content-type-definitions/add'),
                disabled: ctdLimitExceeded,
                menuItems: Object.values(DEFINED_TYPES).map((type) => ({
                  key: type.name,
                  label: type.label,
                  link: buildUrlWithSpace(
                    `content-type-definitions/add?defaultType=${type.name}`,
                  ),
                })),
                ...getTestProps(testId, 'action-menu', 'testId'),
              },
            ]
          : [],
      },
    }));
  }, [
    updateAppContext,
    t,
    permissions,
    addCtdButtonLabel,
    ctdLimitExceeded,
    buildUrlWithSpace,
    testId,
  ]);

  useOnce(handlePageUpdate);

  const handlePageChange = useCallback((newPage) => setPage(newPage), []);

  const handleDeleteType = useCallback(
    async (contentTypeName) => {
      modal.deleting('delete-modal');
      try {
        const { body, status } = await deleteContentType(jwt, space, {
          contentTypeName,
        });
        checkResponseStatus(body, status);
        toast.success(
          t('ContentDefinition.Deleted', { type: contentTypeName }),
        );
        reloadCtd();
        reloadCtdCount();
        reloadSideBarCtd?.();
        if (currentFolderName && currentCtdData.length <= 1) {
          setCurrentFolderName();
        }
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message
              ? error.message
              : t('ContentDefinition.DeletingError', { type: contentTypeName }),
          );
        }
      }
    },
    [
      modal,
      jwt,
      t,
      reloadCtd,
      reloadCtdCount,
      reloadSideBarCtd,
      currentCtdData,
      currentFolderName,
      space,
    ],
  );

  const showDeleteModal = useCallback(
    (name) => {
      modal.delete(t('ContentDefinition.ConfirmDelete'), 'delete-modal', () =>
        handleDeleteType(name),
      );
    },
    [modal, handleDeleteType, t],
  );

  const searchByOptions = useMemo(
    () => [
      { value: 'label', label: t('ContentDefinition.Label') },
      { value: 'name', label: t('ContentDefinition.Name') },
    ],
    [t],
  );

  const setSearchBy = useCallback((_, value) => {
    setQueryBy(value);
    setPage(1);
  }, []);

  const setFilterQuery = useCallback(
    (event) => {
      if (event.target.value?.[0] === '/') {
        setSearchError(t('ContentDefinition.SearchErrorSlash'));
      } else {
        setSearchError();
        setCurrentFolderName();
        setQuery(event.target.value);
        setPage(1);
      }
    },
    [t],
  );

  const handleFolderClick = useCallback((name) => {
    setCurrentFolderName(name);
  }, []);

  return (
    <div className="flex items-stretch w-full min-h-[calc(100vh-71px)]">
      <Helmet>
        <title>{t('Global.Dashboard')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div className="flex flex-col px-5 lg:px-7 py-7 w-full h-full gap-5">
          {initDataHasContent && (
            <div
              className={twMerge(
                'grid grid-flow-row 2xl:grid-flow-col',
                'auto-cols-auto items-end gap-5 xl:gap-8',
              )}
            >
              <div
                className={twMerge(
                  'h-fit py-3 px-4 bg-white dark:bg-slate-950 flex flex-wrap xs:flex-nowrap',
                  'items-center justify-between rounded-lg gap-5',
                )}
              >
                <Input
                  placeholder={t('ContentDefinition.Search')}
                  additionalClasses="w-full xs:w-2/3"
                  type="search"
                  onChange={setFilterQuery}
                  value={query}
                  error={searchError}
                  additionalInputErrorClasses={
                    'absolute -bottom-3 left-2 bg-white px-2 py-0'
                  }
                  additionalInputClasses={twMerge(
                    searchError && 'focus:border-red',
                  )}
                />
                <Dropdown
                  options={searchByOptions}
                  onChange={setSearchBy}
                  value={queryBy}
                  additionalOptionsClasses="!py-1.5"
                  additionalClasses="w-full xs:w-1/3"
                  testId={testId}
                  renderEmpty={() => (
                    <div className="p-2 text-slate-400/80 text-sm text-center">
                      {t('Global.NoData')}
                    </div>
                  )}
                />
              </div>
              <div
                className={twMerge(
                  'py-3 px-4 flex flex-col xs:flex-row bg-white dark:bg-slate-950 items-start',
                  'xs:items-center justify-between rounded-lg grow xs:space-x-6',
                )}
              >
                <div
                  className={twMerge(
                    'grid grid-cols-1 gap-6 w-full grow',
                    'sm:grid-cols-[repeat(auto-fill,_minmax(16rem,_1fr))]',
                    'xl:grid-cols-[repeat(auto-fill,_minmax(10rem,_1fr))] 2xl:grid-cols-2',
                  )}
                >
                  {resources.map((resource) => (
                    <ProgressBar
                      key={resource.key}
                      barBackgroundColor={resource.barColor}
                      progressBarLabel={resource.label}
                      completionPercentValue={
                        resource.limit === -1
                          ? 100
                          : (resource.value / resource.limit) * 100
                      }
                      outOfCompletionTotalValue={
                        resource.limit === -1
                          ? t('Global.Unlimited')
                          : resource.limit
                      }
                      outOfCompletionValue={resource.value}
                      barThickness="thin"
                      additionalCompletionValuesContainerClasses="whitespace-nowrap"
                      hidePercentValue
                    />
                  ))}
                </div>
                {isAdmin && (
                  <LinkButton
                    buttonSize="sm"
                    additionalClasses={twMerge(
                      'whitespace-nowrap h-9 lg:h-10 text-sm sm:text-base mt-4 xs:mt-0 text-white',
                    )}
                    link={`/space/upgrade/${space}`}
                  >
                    {t('Global.UpgradePlan')}
                  </LinkButton>
                )}
              </div>
            </div>
          )}
          {currentCtdData.length > 0 &&
          !ctdAreLoading &&
          !ctdWithCountsAreLoading ? (
            <div className="flex-col justify-center space-y-14 dark:bg-transparent">
              <div className="h-fit grid gap-2 sm:gap-6 grid-cols-[repeat(auto-fill,_minmax(16rem,_1fr))]">
                {currentCtdData.map((element) => (
                  <TypeDefinitionCard
                    key={element.id}
                    imageUrl={
                      element.folder
                        ? imageFolder
                        : featuredImages[element.name]
                    }
                    imageUrlAlt={element.name}
                    imageUrlLoading={featuredImagesLoading}
                    contentType={element}
                    objectCount={
                      element.folder
                        ? element.children.length
                        : ctdWithCounts?.data?.[element.name]
                    }
                    onDelete={(name) => showDeleteModal(name)}
                    isFolder={element.folder}
                    onClickFolder={handleFolderClick}
                    {...getTestProps(testId, `card-${element.name}`, 'testId')}
                  />
                ))}
              </div>
              {pagination && (
                <Pagination
                  page={page}
                  numOfPages={ctdPagination?.total_pages}
                  onPageChange={handlePageChange}
                  additionalClasses="!p-0"
                  {...getTestProps(testId, 'pagination', 'testId')}
                />
              )}
            </div>
          ) : (
            <div
              className="flex flex-col items-center justify-center bg-white dark:bg-slate-950 h-full rounded-lg p-6
              lg:px-12"
            >
              {handleEmptyResult}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContentTypeDefinitions;

ContentTypeDefinitions.propTypes = {
  /**
   * Page test id
   */
  testId: PropTypes.string,
  /**
   * CTD fetch limit
   */
  limit: PropTypes.number,
  /**
   * CTD pagination
   */
  pagination: PropTypes.bool,
};

ContentTypeDefinitions.defaultProps = {
  testId: '',
  limit: 16,
  pagination: true,
};
